import React, { useState, useEffect } from 'react';
import axios from 'axios';
import URL from '../config/config';
// import "./App.css";
import {
	Table,
	Input,
	Modal,
	Button,
	Divider,
	Icon,
	Tag,
	Card,
	DatePicker,
	Form,
	Checkbox,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import ReactJson from 'react-json-view';
import timeConvert from '../helper/data_convert';
import { SearchOutlined, LoadingOutlined, LinkOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import Layout from './layout';

const { RangePicker } = DatePicker;

export default function SearchRawData() {
	function useQueryParams() {
		return new URLSearchParams(useLocation().search);
	}
	let params = useQueryParams();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [modalData, setModalData] = useState('');
	const [modalPrettify, setModalPrettify] = useState(true);

	const layout = {
		labelCol: {
			span: 8,
		},
		wrapperCol: {
			span: 16,
		},
	};
	const tailLayout = {
    wrapperCol: {
      md: {offset: 8,
      span: 16,},
      xs: {}
    },
  };

	const onFinish = async (values) => {
		// console.log('Success:', values);
		const { vin, when } = values;
		const _when = when
			? { from: when[0].toISOString(), to: when[1].toISOString() }
			: {};
		const deviceID = values.deviceID ? { deviceID: values.deviceID } : {};
		const param = { vin, ...deviceID, ..._when };
		console.log(param);

		setLoading(true);
		await axios({
			method: 'post',
			url: URL.server + '/finddata',
			data: param,
			params: params,
		}).then((res) => {
			setData(res.data);
		});
		setLoading(false);
		localStorage.setItem('searchRaw', JSON.stringify(values));
		// console.log(data);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	useEffect(() => {
		console.log('result data', data);
	});

	const closeModal = () => {
		setModalActive(false);
		setModalData('');
	};

	const columns = [
		{
			title: 'Date Create',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '12%',
			render: (text, record) => (
				<Link to={`/datapackage?id=${record._id}`} target='_blank' >{timeConvert(text)} <LinkOutlined/></Link>
			)
		},
		{
			title: 'vin',
			dataIndex: 'vin',
			key: 'vin',
			width: '13%',
		},
		{
			title: 'deviceID',
			dataIndex: 'deviceID',
			key: 'deviceID',
			width: '15%',
		},
		{
			title: 'readableData',
			dataIndex: 'readableData',
			key: 'readableData',
			width: '10%',
			render: (text) => (
				<div
					style={{
						// color: "#ffcc00",
						overflowX: 'scroll',
					}}
				>
					{text && !text.includes('Data type does not support yet!') ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => {
									setModalActive(true);
									setModalData(text);
								}}
							>
								{JSON.parse(text).report === null ? (
								<div>
									<Divider type="vertical" />
									{/* <span style={{ color: "red" }}>NULL</span> */}
									<Tag color={'red'}>NULL</Tag>
								</div>
							) : <>Data</>}
							</Button>
						</div>
					) : (
						<p>{text}</p>
					)}
				</div>
			),
		},
		{
			title: 'rawData',
			dataIndex: 'rawData',
			key: 'rawData',
			width: '30%',
			render: (text) => (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						// gap: 5,
						justifyContent: 'flex-end',
						alignItems: 'center',
						overflow: 'scroll',
						// maxWidth: 400,
						maxHeight: 60,
					}}
				>
					{JSON.parse(text)['api_server_ip'] === '34.210.160.172' ? (
						<Tag color={'green'}>Stag</Tag>
					) : (
						<Tag color={'volcano'}>Dev</Tag>
					)}
					{JSON.parse(text)['data_type'] ? (
						<Tag color={'blue'}>{JSON.parse(text)['data_type']}</Tag>
					) : (
						JSON.parse(text).rawExtracted.map((a) => {
              let typelink = a.data_type.includes('unknow') ? 'unknow Type' : a.data_type;
              return (
							<Tag color={'blue'}><Link to={`/rawdatabytype?type=${typelink}`} target='_blank' >{a.data_type} <LinkOutlined/></Link></Tag>
						)})
					)}
					{/* <Divider type="vertical" /> */}
					<Button
						onClick={() => {
							setModalActive(true);
							setModalData(text);
						}}
					>
						Data
					</Button>
				</div>
			),
		},
		{
			title: 'payload from tool',
			dataIndex: 'payLoad',
			key: 'payLoad',
			// width: "10%",
			render: (text) => (
				<div
					style={{
						color: '#ef00ef',
						overflowX: 'scroll',
						// width: "90%",
						maxWidth: 400,
						maxHeight: 60,
					}}
				>
					{text}
				</div>
			),
		},
	];
	function validatingJSON(json) {
		var checkedjson;
		try {
			checkedjson = JSON.parse(json);
		} catch (e) {}
		return checkedjson;
	}

	return (
		<Layout
			selectedKeys={['searchrawdata']}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				minHeight: '85vh',
			}}
		>
			{/* <h3 style={{ color: 'red', textAlign: 'center' }}>
				(under construction)
			</h3> */}
			<Modal
				title="Detail"
				visible={modalActive}
				onCancel={closeModal}
				style={{ top: 30 }}
				width="700px"
				bodyStyle={{
					maxHeight: '80vh',
					overflow: 'scroll',
					// padding: 5
				}}
				footer={[
					<Button key="back" onClick={closeModal}>
						Return
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							setModalPrettify(!modalPrettify);
						}}
						icon="code"
					>
						{modalPrettify ? <span>String</span> : <span>Prettify</span>}
					</Button>,
				]}
			>
				{modalPrettify ? (
					validatingJSON(modalData) ? (
						<ReactJson
							src={JSON.parse(modalData)}
							indentWidth={0}
							name={false}
							displayDataTypes={false}
						/>
					) : (
						<span>Invalid object!</span>
					)
				) : (
					modalData
				)}
			</Modal>
			{loading && (
				<h3 style={{ textAlign: 'center' }}>
					<span style={{ color: '#1890ff' }}>
						<LoadingOutlined />
					</span>{' '}
					loading...
				</h3>
			)}
			{data.length === 0 ? (
				<Card
					title={<>Search all rawData</>}
					bordered={true}
					className="login-form"
					style={{ width: '30%', minWidth: 300 }}
				>
					<Form
						{...layout}
						name="basic"
						initialValues={
							JSON.parse(localStorage.getItem('searchRaw')) === null
								? {}
								: JSON.parse(localStorage.getItem('searchRaw')).remember
								? {
										remember: true,
										vin: JSON.parse(localStorage.getItem('searchRaw')).vin,
										deviceID: JSON.parse(localStorage.getItem('searchRaw'))
											.deviceID,
										// when: JSON.parse(localStorage.getItem("searchRaw")).when,
								  }
								: {}
						}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
					>
						<Form.Item
							label="vin"
							name="vin"
							rules={[
								{
									required: true,
									message: 'Please input your vin',
								},
							]}
						>
							<Input placeholder="Enter vin" />
						</Form.Item>

						<Form.Item
							label="deviceID"
							name="deviceID"
							rules={[
								{
									required: false,
									message: 'Please input your deviceID',
								},
							]}
						>
							<Input placeholder="Enter deviceID" />
						</Form.Item>

						<Form.Item label="when" name="when">
							<RangePicker format="YYYY-MM-DD" />
						</Form.Item>

						{/* <Form.Item label="date from" name="from">
						<Input />
					</Form.Item>

					<Form.Item label="date to" name="to">
						<Input />
					</Form.Item> */}

						<Form.Item {...tailLayout} name="remember" valuePropName="checked">
							<Checkbox>Remember me</Checkbox>
						</Form.Item>

						<Form.Item {...tailLayout}>
							<Button type="primary" htmlType="submit" disabled={loading}>
								<SearchOutlined /> Search
							</Button>
						</Form.Item>
					</Form>
				</Card>
			) : (
				<Table
					columns={columns}
					dataSource={data}
					rowKey={(record)=>record._id}
					size="middle"
					bordered
					loading={loading}
					title={() => (
						<h3 style={{ textAlign: 'center' }}>
							<p>
								Find <span style={{ color: '#1890ff' }}>{data.length}</span>{' '}
								result
							</p>
							<Button
								type="dashed"
								onClick={() => {
									setData([]);
								}}
							>
								Clear
							</Button>
						</h3>
					)}
					pagination={{ pageSize: 50 }}
					scroll={{ y: '75vh' }}
				/>
				// <Card>{JSON.stringify(data)}</Card>
			)}
		</Layout>
	);
}
