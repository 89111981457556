import { Layout, Menu, Drawer, Button } from 'antd';
import {
	AppstoreOutlined,
	SettingOutlined,
	HomeOutlined,
  MenuOutlined,
  StarOutlined,
  FileOutlined,
  FileSearchOutlined,
  FileExcelOutlined,
	ProfileOutlined,
  GoogleOutlined,
  FileUnknownOutlined,
	UserOutlined,
	FieldStringOutlined,
	TabletOutlined
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useState } from 'react';
import React from "react";
import 'antd/dist/antd.css';
import './style.css';
const { Header, Content, Footer, Sider } = Layout;

export default function AppLayout({ children, selectedKeys }) {
	const [visible, setVisible] = useState(false);

	return (
		<Layout className="layout">
			<Header style={{ background: '#333' }} className="layout-header">
				<a href="/" className="logo">
					<img src="./logo.png" alt="logo" />
				</a>
				<div className="header-btn">
					<Button
						className="barsMenu"
						type="primary"
						onClick={() => setVisible(true)}
					>
						<MenuOutlined style={{ fontSize: '16px' }} />
					</Button>
				</div>
				<Drawer
					title="Nav"
					placement="right"
					// closable={false}
					onClose={() => setVisible(false)}
					visible={visible}
				>
					<Menu selectedKeys={selectedKeys}>
						<Menu.Item key="home" icon={<HomeOutlined />}>
							<Link to="/">home</Link>
						</Menu.Item>
						<Menu.Item key="rawdata" icon={<FileOutlined />}>
							<Link to="/rawdata">rawData</Link>
						</Menu.Item>
						<Menu.Item key="searchrawdata" icon={<FileSearchOutlined />}>
              <Link to="/searchrawdata">searchRawData</Link>
						</Menu.Item>
            <Menu.Item key="xmldata" icon={<FileExcelOutlined />}>
              <Link to="/xmldata">xmlData</Link>
						</Menu.Item>
            <Menu.Item key="getprofile" icon={<ProfileOutlined />}>
              <Link to="/getprofile">getProfile</Link>
						</Menu.Item>
            <Menu.Item key="gpsdemo" icon={<GoogleOutlined />}>
              <Link to="/gpsdemo">gpsDemo</Link>
						</Menu.Item>
            <Menu.Item key="doc" icon={<FileUnknownOutlined />}>
              <Link to="/doc">doc</Link>
						</Menu.Item>
						<Menu.Item key="hexstring" icon={<FieldStringOutlined />}>
              <Link to="/hexstring">Submit HexString</Link>
						</Menu.Item>
						<Menu.Item key="databydevice" icon={<TabletOutlined />}>
              <Link to="/databydevice">Data with Gps</Link>
						</Menu.Item>
						{/* <Menu.Item key="4" icon={<SettingOutlined />} disabled>
							nav 4
						</Menu.Item> */}
					</Menu>
				</Drawer>
			</Header>
			<Content className="main-section">{children}</Content>
			<Footer style={{ textAlign: 'center', borderTop: '1px solid #d0d2d6' }}>
				Innova ©2020
			</Footer>
		</Layout>
	);
}
