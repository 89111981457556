import React, { useState } from 'react';
import URL from '../config/config';
import {
	Card,
	Row,
	Col,
	Button,
	Alert,
	Form,
	Input,
	Select,
	Layout as AntLayout,
	DatePicker,
	Menu,
	Divider,
	Collapse,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import XMLViewer from 'react-xml-viewer';
import ReactJson from 'react-json-view';
import Layout from './layout';
import axios from 'axios';
import timeConvert from '../helper/data_convert';
import {
	withScriptjs,
	withGoogleMap,
	LoadScript,
	Polyline,
	GoogleMap,
	Marker,
	DirectionsRenderer,
} from 'react-google-maps';
import Map from './gps_helper';
import secret from "../config/api_key";
// import { Link, useLocation } from "react-router-dom";
const { Header, Content, Footer, Sider } = AntLayout;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

export default function DataByDevice() {
	// let {search} = useLocation();
	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};
	const tailLayout = {
		wrapperCol: { offset: 4, span: 16 },
	};

	const [form] = Form.useForm();
	const [message, setMessage] = useState();
	const [err, setErr] = useState();
	const [value, setValue] = useState();
	const [dataDTC, setDTC] = useState();
	const [dataOBD2LIVEDATA, setOBD2LIVEDATA] = useState();
	const [dataOEM, setOEM] = useState();
	const [dataOEMLIVEDATA, setOEMLIVEDATA] = useState();

	const [dataGPS, setGPS] = useState();
	const [gpsTrip, setGpsTrip] = useState();

	const onFinish = async (values) => {
		const url = `https://locals.innovavietnam.com/latestdatabydevice`;
		console.log('Success:', values);
		setValue(values);
		const { deviceid, vin } = values;
		const linkDTC = !vin
			? `${url}?deviceid=${deviceid}&type=Dtc`
			: `${url}?deviceid=${deviceid}&type=Dtc&vin=${vin}`;
		const linkODB2LiveDataPID = !vin
			? `${url}?deviceid=${deviceid}&type=OBD2 LiveData PID Value`
			: `${url}?deviceid=${deviceid}&type=OBD2 LiveData PID Value&vin=${vin}`;
		const linkOEMDATA = !vin
			? `${url}?deviceid=${deviceid}&type=OemData`
			: `${url}?deviceid=${deviceid}&type=OemData&vin=${vin}`;
		const linkOEMLiveData = !vin
			? `${url}?deviceid=${deviceid}&type=OEM LiveData`
			: `${url}?deviceid=${deviceid}&type=OEM LiveData&vin=${vin}`;
		const linkGPS = !vin
			? `${url}?deviceid=${deviceid}&type=Gps`
			: `${url}?deviceid=${deviceid}&type=Gps&vin=${vin}`;
		localStorage.setItem('recent', JSON.stringify(values));

		axios
			.get(linkDTC, values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				// console.log(res);
				setDTC(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setDTC(err);
			});
		axios
			.get(linkODB2LiveDataPID, values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				// console.log(res);
				// let x = await JSON.parse(res.data[0].readableData).filter(a => JSON.stringify(a).includes('odb2LiveItems'))
				// console.log(x)
				setOBD2LIVEDATA(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setOBD2LIVEDATA(err);
			});
		axios
			.get(linkOEMDATA, values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				// console.log(res);
				setOEM(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setOEM(err);
			});
		axios
			.get(linkOEMLiveData, values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				// console.log(res);
				setOEMLIVEDATA(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setOEMLIVEDATA(err);
			});
		axios
			.get(linkGPS, values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				// console.log(res);
				setGPS(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setGPS(err);
			});

		const config = {
			url: 'https://locals.innovavietnam.com/gpsdatabytime',
      // url: 'http://localhost:4004/gpsdatabytime',
			method: 'post',
			headers: {},
			data: {
				deviceID: deviceid,
			},
		};

		axios.request(config).then(async (res) => {
      console.log(res.data)
      let arr = await res.data.map(a => {
        let data = JSON.parse(a.readableData)[0]
				let result = {
          lat: parseFloat(data.latitude),
          lng: parseFloat(data.longitude),
          timestamp: data.timeOfFix,
          vin: a.vin || "",
          deviceID: a.deviceID,
        }
        return result
      })
			let Arr = await arr.filter(a => a.lat !== 0.0000000 && a.lng !== 0.0000000 && a);
			let origin = Arr.slice(-1)[0];
			let des = Arr[0];
			let waypoint = await Arr.slice(1,-1).map(a => {
				return { location: { lat: a.lat, lng: a.lng }, stopover: true }
			})
			setGpsTrip({
				origin: origin,
				des: des,
				waypoint: waypoint,
			});
			console.log(Arr.slice(-1)[0]);
		});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};
	const MapLoader = withScriptjs(Map);
	return (
		<Layout selectedKeys={['databydevice']}>
			<AntLayout style={{ width: '100%' }}>
				{/* <Sider>
					<Menu
						mode="inline"
						defaultSelectedKeys={['1']}
						style={{ height: '100%' }}
					>
						<Menu.Item key="1">option1</Menu.Item>
						<Menu.Item key="2">option2</Menu.Item>
						<Menu.Item key="3">option3</Menu.Item>
						<Menu.Item key="4">option4</Menu.Item>
						<Menu.Item key="5">option5</Menu.Item>
						<Menu.Item key="6">option6</Menu.Item>
						<Menu.Item key="7">option7</Menu.Item>
						<Menu.Item key="8">option8</Menu.Item>
						<Menu.Item key="9">option9</Menu.Item>
						<Menu.Item key="10">option10</Menu.Item>
						<Menu.Item key="11">option11</Menu.Item>
						<Menu.Item key="12">option12</Menu.Item>
					</Menu>
				</Sider> */}

				<Card style={{ width: '100%', minHeight: '80vh' }}>
					{message && (
						<Alert message={JSON.stringify(message)} type="success" />
					)}
					{err && <Alert message={JSON.stringify(err)} type="error" />}

					{!value ? (
						<Card type="inner" style={{ width: 'fit-content' }}>
							<Form
								{...layout}
								name="basic"
								form={form}
								// initialValues={localStorage.getItem('recent') ? JSON.parse(localStorage.getItem('recent')) : {}}
								onFinish={onFinish}
								onFinishFailed={onFinishFailed}
							>
								{/* <Form.Item
					label="Server"
					name="server"
					rules={[{ required: true, message: 'Please input your server!' }]}
				>
					<Select>
						<Select.Option value="dev">dev</Select.Option>
						<Select.Option value="stag">stag</Select.Option>
					</Select>
				</Form.Item> */}

								<Form.Item
									label="Device ID"
									name="deviceid"
									rules={[
										{ required: true, message: 'Please input your device id!' },
									]}
								>
									<Input placeholder="enter deviceID/GUID" />
								</Form.Item>

								<Form.Item
									label="Vin"
									name="vin"
									// rules={[{ required: true, message: 'Please input your payload!' }]}
								>
									<Input placeholder="enter vin" />
								</Form.Item>

								{/* <Form.Item label="when" name="when">
					<RangePicker format="DD-MM-YYYY" />
				</Form.Item> */}

								<Form.Item
								// {...tailLayout}
								>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>{' '}
									<Button
										onClick={() => {
											// await localStorage.clear();
											form.resetFields();
											setMessage();
											setErr();
										}}
									>
										clear
									</Button>{' '}
									<Button
										onClick={() => {
											// let data = await JSON.parse(localStorage.getItem('recent'))
											// console.log(data)
											form.setFieldsValue(
												JSON.parse(localStorage.getItem('recent'))
											);
										}}
									>
										recent
									</Button>
								</Form.Item>
							</Form>
						</Card>
					) : (
						<Card type="inner">
							<Button
								danger
								onClick={() => {
									setValue();
								}}
							>
								Clear
							</Button>{' '}
							<Button
								onClick={() => {
									onFinish(value);
								}}
							>
								reFetch
							</Button>
							<Row gutter={16}>
								<Col span={12} style={{maxHeight: 1000, overflow: "scroll"}} >
									<p>deviceID: {value ? value.deviceid : <></>}</p>
									<p>vin: {value ? value.vin : <></>}</p>
									<Collapse>
										<Panel
											header={`DTC | ${
												dataDTC && dataDTC[0] && dataDTC[0].vin
											} | ${
												dataDTC &&
												dataDTC[0] &&
												timeConvert(dataDTC[0].createdAt)
											}`}
										>
											{dataDTC && dataDTC[0] ? (
												<ReactJson
													src={JSON.parse(dataDTC[0].readableData)}
													name={false}
													displayDataTypes={false}
												/>
											) : (
												<></>
											)}
										</Panel>
										<Panel
											header={`OBD2 Live Data | ${
												dataOBD2LIVEDATA &&
												dataOBD2LIVEDATA[0] &&
												dataOBD2LIVEDATA[0].vin
											} | ${
												dataOBD2LIVEDATA &&
												dataOBD2LIVEDATA[0] &&
												timeConvert(dataOBD2LIVEDATA[0].createdAt)
											}`}
										>
											{dataOBD2LIVEDATA && dataOBD2LIVEDATA[0] ? (
												<ReactJson
													src={JSON.parse(dataOBD2LIVEDATA[0].readableData)}
													name={false}
													displayDataTypes={false}
												/>
											) : (
												<></>
											)}
										</Panel>
										<Panel
											header={`OEM | ${
												dataOEM && dataOEM[0] && dataOEM[0].vin
											} | ${
												dataOEM &&
												dataOEM[0] &&
												timeConvert(dataOEM[0].createdAt)
											}`}
										>
											{dataOEM && dataOEM[0] ? (
												<ReactJson
													src={JSON.parse(dataOEM[0].readableData)}
													name={false}
													displayDataTypes={false}
												/>
											) : (
												<></>
											)}
										</Panel>
										<Panel
											header={`OEM Live Data | ${
												dataOEMLIVEDATA &&
												dataOEMLIVEDATA[0] &&
												dataOEMLIVEDATA[0].vin
											} | ${
												dataOEMLIVEDATA &&
												dataOEMLIVEDATA[0] &&
												timeConvert(dataOEMLIVEDATA[0].createdAt)
											}`}
										>
											{dataOEMLIVEDATA && dataOEMLIVEDATA[0] ? (
												<ReactJson
													src={JSON.parse(dataOEMLIVEDATA[0].readableData)}
													name={false}
													displayDataTypes={false}
												/>
											) : (
												<></>
											)}
										</Panel>
										<Panel
											header={`GPS | ${
												dataGPS && dataGPS[0] && dataGPS[0].vin
											} | ${
												dataGPS &&
												dataGPS[0] &&
												timeConvert(dataGPS[0].createdAt)
											}`}
										>
											{dataGPS && dataGPS[0] ? (
												<>
													<ReactJson
														src={JSON.parse(dataGPS[0].readableData)}
														name={false}
														displayDataTypes={false}
													/>
													<iframe
														title="map"
														width="100%"
														height="300"
														frameborder="0"
														scrolling="no"
														marginheight="0"
														marginwidth="0"
														src={`https://maps.google.com/maps?q=${
															JSON.parse(dataGPS[0].readableData)[0].latitude
														},${
															JSON.parse(dataGPS[0].readableData)[0].longitude
														}&hl=&z=12&amp&iwloc=&output=embed&ie=UTF8`}
													/>
												</>
											) : (
												<></>
											)}
										</Panel>
									</Collapse>
								</Col>
								<Col
									style={{
										borderLeft: '1px solid #f0f0f0',
										overflow: 'scroll',
									}}
									span={12}
								>
									map
									{/* {gpsTrip && <p>{JSON.stringify(gpsTrip)}</p>} */}
                  {!gpsTrip && <>loading...</>}
									{gpsTrip ? <Card
										title={gpsTrip && gpsTrip.des ? gpsTrip.des.vin + " / " + gpsTrip.des.deviceID : undefined}
										style={{ margin: 10, width: '100%' }}
									>
                    {/* //${secret.gmap_api_key} */}
										<MapLoader
											googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secret.gmap_api_key}&v=3.exp&libraries=geometry,drawing,places`}
											loadingElement={
												<div style={{ height: `100%`, width: `100%` }} />
											}
											// origin={this.state.mapData.origin}
											// destination={this.state.mapData.destination}
											// waypoints={this.state.mapData.waypoints}
											// mock data
											// origin={{ lat: 10.805029, lng: 106.642711 }}
											// destination={{ lat: 10.802152, lng: 106.641101 }}
											origin={gpsTrip.origin}
											destination={gpsTrip.des}
											waypoints={gpsTrip.waypoint}
										/>
									</Card> : <p>gps data not valid</p>}
								</Col>
							</Row>
						</Card>
					)}
				</Card>
			</AntLayout>
		</Layout>
	);
}
