/*global google*/

import React from "react";
import {
	Table,
	Divider,
	Button,
	Icon,
	Select,
	Input,
	Tag,
	Typography,
	Form,
	Card,
	Row,
	Col
} from "antd";
import "antd/dist/antd.css";
import ReactJson from "react-json-view";
import { createApolloFetch } from "apollo-fetch";
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	DirectionsRenderer
} from "react-google-maps";
import secret from "../config/api_key";
import Map from './gps_helper';
import Layout from './layout';

// class Map extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			directions: null
// 		};
// 	}

// 	componentDidMount() {
// 		const directionsService = new google.maps.DirectionsService();

// 		const origin = this.props.origin;
// 		const destination = this.props.destination;
// 		const waypoints = this.props.waypoints;
// 		// const destination = null;

// 		destination &&
// 			directionsService.route(
// 				{
// 					origin: origin,
// 					destination: destination,
// 					travelMode: google.maps.TravelMode.DRIVING,
// 					waypoints: waypoints
// 				},
// 				(result, status) => {
// 					if (status === google.maps.DirectionsStatus.OK) {
// 						this.setState({
// 							directions: result
// 						});
// 					} else {
// 						// console.error(`error fetching directions ${result}`);
// 						console.log("error");
// 					}
// 				}
// 			);
// 	}

// 	render() {
// 		const GoogleMapWindow = withGoogleMap(props => (
// 			<GoogleMap
// 				defaultCenter={this.props.origin}
// 				defaultZoom={13}
// 				options={{ streetViewControl: false }}
// 			>
// 				{this.state.directions ? (
// 					<DirectionsRenderer directions={this.state.directions} />
// 				) : (
// 					<Marker
// 						position={this.props.origin}
// 						label={{ text: "S", color: "white" }}
// 					/>
// 				)}
// 			</GoogleMap>
// 		));

// 		return (
// 			<div>
// 				<GoogleMapWindow
// 					containerElement={<div style={{ height: `600px`, width: "100%" }} />}
// 					mapElement={<div style={{ height: `100%` }} />}
// 				/>
// 			</div>
// 		);
// 	}
// }

class Gps_example extends React.Component {
	constructor() {
		super();
		this.state = {
			exampleMap: true
		};
	}

	componentDidUpdate() {}

	render() {
		const MapLoader = withScriptjs(Map);

		return (
			<Layout selectedKeys={['gpsdemo']} >
				{this.state.exampleMap && (
					<Card title="example with mockupdata" style={{ margin: 10, width: "100%" }}>
						<MapLoader
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secret.gmap_api_key}&v=3.exp&libraries=geometry,drawing,places`}
							loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
							// origin={this.state.mapData.origin}
							// destination={this.state.mapData.destination}
							// waypoints={this.state.mapData.waypoints}
							// mock data
							// origin={{ lat: 10.805029, lng: 106.642711 }}
							// destination={{ lat: 10.802152, lng: 106.641101 }}
							origin={{ lat: 10.80674, lng: 106.647972 }}
							destination={{ lat: 10.83046, lng: 106.64287 }}
							waypoints={[
								{ location: { lat: 10.803048, lng: 106.64241 }, stopover: true }
							]}
						/>
					</Card>
				)}
			</Layout>
		);
	}
}

export default Gps_example