import React from 'react';
import {
	Table,
	Divider,
	Button,
	Select,
	Input,
	Tag,
	Typography,
	Upload,
	Tabs,
} from 'antd';
import 'antd/dist/antd.css';
import { CloudServerOutlined, FileProtectOutlined, LoadingOutlined, FileExcelOutlined, FileOutlined, CarOutlined, CloseCircleOutlined, StopOutlined, InfoCircleOutlined, UploadOutlined, SearchOutlined } from '@ant-design/icons';
import { createApolloFetch } from 'apollo-fetch';
import exportFromJSON from 'export-from-json';
import Layout from './layout';
const csv = require('papaparse');

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { Title } = Typography;

async function get_profile(vin, server) {
	let data;
	const fetch = createApolloFetch({
		// uri: `http://${server}:4000/graphql`,
		uri: `https://${server}.innovavietnam.com/graphql`
	});
	let a = performance.now();
	await fetch({
		query: `
			query getProfile($vin: String!) {
        getProfile(vin: $vin) {
				  profile_size
          profile_crc32
        }
			}
		`,
		variables: {
			vin: vin,
		},
	})
		.then((res) => {
			// let apiRes = res.data;
			console.log('res data: ', JSON.stringify(res.data));
			res.errors && console.log('Err:', res.errors);
			console.log(res.data.getProfile);
			// resdata = res.errors ? res.errors : res.data.getProfile;
			data = res.errors ? res.errors[0] : res.data.getProfile;
		})
		.catch((err) => {
			console.log(err);
			data = err;
		});
	let timeTook = performance.now() - a;
	return { data, timeTook };
}
// let resdata;

async function get_decodeVin(vin, server) {
	let data;
	const fetch = createApolloFetch({
		// uri: `http://${server}:4000/graphql`,
		uri: `https://${server}.innovavietnam.com/graphql`
	});
	let a = performance.now();
	await fetch({
		query: `
			query decodeVin($vin: String!) {
        decodeVin(vin: $vin) {
					year
					make
					manufacturer
					model
					engine
					trim
					option
					year_enum
					make_enum
					manufacturer_enum
					model_enum
					engine_enum
					trim_enum
					option_enum
				}
			}
		`,
		variables: {
			vin: vin,
		},
	})
		.then((res) => {
			console.log('res data: ', JSON.stringify(res.data));
			res.errors && console.log('Err:', res.errors);
			console.log('data',res.data);
			data = res.errors ? res.errors[0] : res.data.decodeVin;
		})
		.catch((err) => {
			console.log(err);
			data = err;
		});
	let timeTook = performance.now() - a;
	return { data, timeTook };
}

export default class GetProfile extends React.Component {
	constructor() {
		super();
		this.state = {
			// Server: '54.254.225.5',
			Server: 'dev',
			loading: false,
			listVIN: [],
			table_data: [],
			fileList: [],
			uploading: false,
			csvData: [],
			break: false
		};
	}

	handleUpload = async () => {
		const { fileList, listVIN } = this.state;
		this.setState({
			uploading: true,
		});
		await fileList.forEach(async (file) => {
			await csv.parse(file, {
				header: true,
				complete: function (results) {
					console.log('Parsing complete:', results);
					let data = results.data.map((a) => a.vin);
					this.setState({
						listVIN: [...listVIN, ...data],
					});
				}.bind(this),
			});
		});
		this.setState({
			uploading: false,
		});
		console.log(this.state.listVIN);

		// You can use any AJAX library you like
	};

	handleChange = (value) => {
		this.setState({ Server: value });
	};

	handleVin(event) {
		let arrVin = event.target.value
			.split(',')
			.map((vin) => vin.trim())
			.filter((a) => a !== '');
		this.setState({ listVIN: arrVin });
	}

	async handleClick() {
		this.setState({
			loading: true,
		});
		for (const vin of this.state.listVIN) {
			let { data, timeTook } = await get_profile(vin, this.state.Server);
			if (this.state.break) break;
			let _data = { vin: vin, data: data, timeTook: timeTook };
			this.setState({
				table_data: [...this.state.table_data, _data],
			});
		}
		this.setState({
			loading: false,
		});
	}

	async handleDecodeVin() {
		this.setState({
			loading: true,
		});
		for (const vin of this.state.listVIN) {
			if (this.state.break) break;
			let { data, timeTook } = await get_decodeVin(vin, this.state.Server);
			let _data = { vin: vin, data: data, timeTook: timeTook };
			this.setState({
				table_data: [...this.state.table_data, _data],
			});
		}
		this.setState({
			loading: false,
		});
	}

	exportData = (type) => {
		let data = this.state.table_data;
		let fileName = 'profile_list';
		let exportType = type;

		exportFromJSON({ data, fileName, exportType });
	};

	clearTable() {
		this.setState({ 
			loading: false,
			listVIN: [],
			table_data: [],
			fileList: [],
			uploading: false,
			csvData: [],
			break: false
		});
	}

	componentDidUpdate() {
		console.log('server:', this.state.Server);
		console.log('table:', this.state.table_data);
		// console.log('csvData', this.state.csvData);
	}

	render() {
		const columns = [
			{
				title: 'VIN',
				dataIndex: 'vin',
				key: 'vin',
				width: '15%',
				render: (text) => <span>{text}</span>,
			},
			{
				title: 'Time Took (ms)',
				dataIndex: 'timeTook',
				key: 'timeTook',
				width: '15%',
				render: (text) => <span>{text}</span>,
			},
			{
				title: 'Data',
				dataIndex: 'data',
				key: 'data',
				width: '50%',
				render: (text) =>
					<div style={{overflow: "scroll"}}>
					{text ? (
						text.profile_size ? (
							<div>
								{text.profile_size && <p>profile_size: {text.profile_size}</p>}
								{text.profile_crc32 && (
									<p>profile_crc32: {text.profile_crc32}</p>
								)}
							</div>
						) : (
							JSON.stringify(text)
						)
						) : (
						'server return null or error'
					)}</div>,
			},
		];
		const dataSource = this.state.table_data;

		const { uploading, fileList } = this.state;
		const props = {
			accept: '.csv',
			onRemove: (file) => {
				this.setState((state) => {
					const index = state.fileList.indexOf(file);
					const newFileList = state.fileList.slice();
					newFileList.splice(index, 1);
					return {
						fileList: newFileList,
					};
				});
			},
			beforeUpload: (file) => {
				this.setState((state) => ({
					fileList: [...state.fileList, file],
				}));
				return false;
			},
			fileList,
		};
		return (
			<Layout selectedKeys={'getprofile'} >
			<div
				style={{
					padding: 10,
					// display: "flex",
					justifyContent: 'center',
					alignItems: 'center',
					width: "100%"
				}}
			>
				<Title level={4}>
					<CloudServerOutlined /> <Divider type="vertical" />
					Server
				</Title>
				<Select
					defaultValue="dev"
					style={{ width: 250 }}
					onChange={this.handleChange}
				>
					<Option value="dev">Dev - 54.254.225.5</Option>
					<Option value="stag">Staging - 34.210.160.172</Option>
					<Option value="prod">Production - 23.20.121.215</Option>
					<Option value="usstag">US Staging - staging-parser.innova.com...</Option>
					<Option value="usprod">US Production - parser1.innova.com...</Option>
				</Select>
				<Divider></Divider>
				<Title level={4}>
					<CarOutlined /> <Divider type="vertical" />
					VIN Input
				</Title>
				<Tabs defaultActiveKey="1">
					<TabPane tab="load csv file" key="1">
						<>
							<Upload {...props}>
								<Button>
									<UploadOutlined /> Load VIN from csv File
								</Button>
								<p>file csv must have column with name "vin"</p>
							</Upload>
							<Button
								type="primary"
								onClick={this.handleUpload}
								disabled={
									fileList.length === 0 || this.state.listVIN.length !== 0
								}
								loading={uploading}
								style={{ marginTop: 16 }}
							>
								{this.state.listVIN.length === 0 ? 'Load vin' : "ok"}
							</Button>
							{/* <Button
						type="primary"
						onClick={this.handleVinFromFile.bind(this)}
						// disabled={fileList.length === 0}
						// loading={uploading}
						style={{ marginTop: 16 }}
					>
						{uploading ? 'loading' : 'get Profile'}
					</Button> */}
						</>
					</TabPane>
					<TabPane tab="type text" key="2">
						<Tag color={'geekblue'}>VIN1</Tag>, <Tag color={'cyan'}>VIN2</Tag>,
						<Tag color={'lime'}>VIN3</Tag>, ...
						<Tag color={'magenta'}>VINn</Tag>,
						<TextArea
							style={{ marginTop: 10 }}
							rows={4}
							placeholder="each item separated by a comma (,)"
							onChange={this.handleVin.bind(this)}
						></TextArea>
					</TabPane>
				</Tabs>

				<Button
					shape="round"
					style={{ marginTop: 10 }}
					type="primary"
					disabled={this.state.loading}
					onClick={this.handleClick.bind(this)}
				>
					<SearchOutlined />
					Get Profile
				</Button>
				<Button
					shape="round"
					style={{ marginTop: 10, marginLeft: 10 }}
					type="primary"
					disabled={this.state.loading}
					onClick={this.handleDecodeVin.bind(this)}
				>
					<InfoCircleOutlined />
					decode Vin
				</Button>
				<Button
					shape="round"
					style={{ marginTop: 10, marginLeft: 10 }}
					disabled={!this.state.loading}
					onClick={()=>{this.setState({break: true})}}
					type="danger"
				>
					<StopOutlined />
					Stop
				</Button>
				<Button
					shape="round"
					style={{ marginTop: 10, marginLeft: 10 }}
					disabled={!this.state.break || this.state.loading}
					onClick={this.clearTable.bind(this)}
					type="dashed"
				>
					<CloseCircleOutlined />
					Clear List
				</Button>
				<>
				{this.state.loading && <h3><LoadingOutlined /> Processing...</h3>}
				</>
				{this.state.table_data.length !== 0 && (
					<>
						<Table
							rowKey={(record) => record.vin}
							dataSource={dataSource}
							columns={columns}
							scroll={{ y: "75vh" }}
							title={() => (
								<>
									<Button
										shape="round"
										type="danger"
										onClick={this.exportData.bind(this, 'txt')}
									>
										<FileOutlined />
										export txt
									</Button>
									<Divider type="vertical" />
									<Button
										shape="round"
										type="danger"
										onClick={this.exportData.bind(this, 'json')}
									>
										<FileOutlined />
										export json
									</Button>
									<Divider type="vertical" />
									<Button
										shape="round"
										type="danger"
										onClick={this.exportData.bind(this, 'csv')}
									>
										<FileProtectOutlined />
										export csv
									</Button>
									<Divider type="vertical" />
									<Button
										shape="round"
										type="danger"
										onClick={this.exportData.bind(this, 'xls')}
									>
										<FileExcelOutlined />
										export xls
									</Button>
								</>
							)}
						/>
					</>
				)}
			</div></Layout>
		);
	}
}
