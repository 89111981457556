import React, { useState, useEffect } from 'react';
import axios from 'axios';
import URL from '../config/config';
// import "./App.css";
import {
	Table,
	Input,
	Modal,
	Button,
	Divider,
	Icon,
	Tag,
	Card,
	DatePicker,
	Form,
	Checkbox,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import ReactJson from 'react-json-view';
import timeConvert from '../helper/data_convert';
import { SearchOutlined, LoadingOutlined, LinkOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';
import Layout from './layout';
import TableTitle from './table_component/title';

export default function RawDataByType() {  
  let {search} = useLocation();
	const [rawData, setRawData] = useState([]);
	const [err, setErr] = useState();

	useEffect(() => {
		rawData.length === 0 &&
			axios
				.get(`${URL.server}/databytype${search}`)
				.then((res) => {
          console.log('getting data')
					setRawData(res.data);
					console.log(res.data);
				})
				.catch((error) => {
					setErr(error);
					console.log(error);
        });
    console.log('load', search)
	}, [rawData, search]);

	// const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [modalData, setModalData] = useState('');
	const [modalPrettify, setModalPrettify] = useState(true);

	// const layout = {
	// 	labelCol: {
	// 		span: 8,
	// 	},
	// 	wrapperCol: {
	// 		span: 16,
	// 	},
	// };
	// const tailLayout = {
  //   wrapperCol: {
  //     md: {offset: 8,
  //     span: 16,},
  //     xs: {}
  //   },
  // };

	// const onFinish = async (values) => {
	// 	// console.log('Success:', values);
	// 	const { vin, when } = values;
	// 	const _when = when
	// 		? { from: when[0].toISOString(), to: when[1].toISOString() }
	// 		: {};
	// 	const deviceID = values.deviceID ? { deviceID: values.deviceID } : {};
	// 	const param = { vin, ...deviceID, ..._when };
	// 	console.log(param);

	// 	setLoading(true);
	// 	await axios({
	// 		method: 'post',
	// 		url: URL.server + '/finddata',
	// 		data: param,
	// 		params: params,
	// 	}).then((res) => {
	// 		setData(res.data);
	// 	});
	// 	setLoading(false);
	// 	localStorage.setItem('searchRaw', JSON.stringify(values));
	// 	// console.log(data);
	// };

	// const onFinishFailed = (errorInfo) => {
	// 	console.log('Failed:', errorInfo);
	// };

	// useEffect(() => {
	// 	console.log('result data', data);
	// });

	const closeModal = () => {
		setModalActive(false);
		setModalData('');
	};

	const columns = [
		{
			title: 'Date Create',
			dataIndex: 'createdAt',
			key: 'createdAt',
			width: '12%',
			render: (text, record) => (
				<Link to={`/datapackage?id=${record._id}`} target='_blank' >{timeConvert(text)} <LinkOutlined/></Link>
			)
		},
		{
			title: 'vin',
			dataIndex: 'vin',
			key: 'vin',
			width: '13%',
		},
		{
			title: 'deviceID',
			dataIndex: 'deviceID',
			key: 'deviceID',
			width: '15%',
		},
		{
			title: 'readableData',
			dataIndex: 'readableData',
			key: 'readableData',
			width: '10%',
			render: (text) => (
				<div
					style={{
						// color: "#ffcc00",
						overflowX: 'scroll',
					}}
				>
					{text && !text.includes('Data type does not support yet!') ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => {
									setModalActive(true);
									setModalData(text);
								}}
							>
								{JSON.parse(text).report === null ? (
								<div>
									<Divider type="vertical" />
									{/* <span style={{ color: "red" }}>NULL</span> */}
									<Tag color={'red'}>NULL</Tag>
								</div>
							) : <>Data</>}
							</Button>
						</div>
					) : (
						<p>{text}</p>
					)}
				</div>
			),
		},
		{
			title: 'rawData',
			dataIndex: 'rawData',
			key: 'rawData',
			width: '30%',
			render: (text) => (
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						// gap: 5,
						justifyContent: 'flex-end',
						alignItems: 'center',
						overflow: 'scroll',
						// maxWidth: 400,
						maxHeight: 60,
					}}
				>
					{JSON.parse(text)['api_server_ip'] === '34.210.160.172' ? (
						<Tag color={'green'}>Stag</Tag>
					) : (
						<Tag color={'volcano'}>Dev</Tag>
					)}
					{JSON.parse(text)['data_type'] ? (
						<Tag color={'blue'}>{JSON.parse(text)['data_type']}</Tag>
					) : (
						JSON.parse(text).rawExtracted.map((a) => {
              let typelink = a.data_type.includes('unknow') ? 'unknow Type' : a.data_type;
              return (
							<Tag color={'blue'}><Link to={`/rawdatabytype?type=${typelink}`} target='_blank' >{a.data_type} <LinkOutlined/></Link></Tag>
						)})
					)}
					{/* <Divider type="vertical" /> */}
					<Button
						onClick={() => {
							setModalActive(true);
							setModalData(text);
						}}
					>
						Data
					</Button>
				</div>
			),
		},
		{
			title: 'payload from tool',
			dataIndex: 'payLoad',
			key: 'payLoad',
			// width: "10%",
			render: (text) => (
				<div
					style={{
						color: '#ef00ef',
						overflowX: 'scroll',
						// width: "90%",
						maxWidth: 400,
						maxHeight: 60,
					}}
				>
					{text}
				</div>
			),
		},
	];
	function validatingJSON(json) {
		var checkedjson;
		try {
			checkedjson = JSON.parse(json);
		} catch (e) {}
		return checkedjson;
	}

	return (
		<Layout
			selectedKeys={['searchrawdata']}
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				minHeight: '85vh',
			}}
		>
			{/* <h3 style={{ color: 'red', textAlign: 'center' }}>
				(under construction)
			</h3> */}
			<Modal
				title="Detail"
				visible={modalActive}
				onCancel={closeModal}
				style={{ top: 30 }}
				width="700px"
				bodyStyle={{
					maxHeight: '80vh',
					overflow: 'scroll',
					// padding: 5
				}}
				footer={[
					<Button key="back" onClick={closeModal}>
						Return
					</Button>,
					<Button
						key="submit"
						type="primary"
						onClick={() => {
							setModalPrettify(!modalPrettify);
						}}
						icon="code"
					>
						{modalPrettify ? <span>String</span> : <span>Prettify</span>}
					</Button>,
				]}
			>
				{modalPrettify ? (
					validatingJSON(modalData) ? (
						<ReactJson
							src={JSON.parse(modalData)}
							indentWidth={0}
							name={false}
							displayDataTypes={false}
						/>
					) : (
						<span>Invalid object!</span>
					)
				) : (
					modalData
				)}
			</Modal>
			{loading && (
				<h3 style={{ textAlign: 'center' }}>
					<span style={{ color: '#1890ff' }}>
						<LoadingOutlined />
					</span>{' '}
					loading...
				</h3>
			)}
			{rawData.length === 0 ? (
				<><LoadingOutlined /> loading...</>
			) : (
				<Table
					columns={columns}
					dataSource={rawData}
					rowKey={(record)=>record._id}
					size="middle"
					bordered
					loading={loading}
					title={() => (
						<h3 style={{ textAlign: 'center' }}>
							<p>
								<span style={{ color: '#1890ff' }}>{rawData.length}</span>{' '}
								latest result
							</p>
              <TableTitle/>
						</h3>
					)}
					pagination={{ pageSize: 50 }}
					scroll={{ y: '75vh' }}
				/>
			)}
		</Layout>
	);
}
