import React from 'react';
import URL from '../config/config';
import {
	Card,
	Row,
	Button,
	Col,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import XMLViewer from 'react-xml-viewer';
import ReactJson from 'react-json-view';
import Layout from './layout';

export default function Doc() {
	return (
		<Layout selectedKeys={'doc'} >
			<Row gutter={[16, 16]}>
				<Col md={12} sm={24} xs={24}>
					<Card title="Submit XML data">
						<h3>
							Submit xml data at{' '}
							<span style={{ color: 'red' }}>
								"http://locals.innovavietnam.com/xml/"
							</span>{' '}
							(encode base64)
						</h3>
						<Card title="Header" type="inner">
							<ReactJson
								src={{
									'x-innova-device': '<device ID>',
									'Content-Type': 'application/xml',
								}}
								indentWidth={0}
								name={false}
								displayDataTypes={false}
							/>
							{/* {JSON.stringify({ 'x-innova-device': '<device ID>', 'Content-Type': 'application/xml'})} */}
						</Card>
						<Card
							title="Example req.body"
							type="inner"
							style={{ maxHeight: '600px', overflow: 'scroll' }}
						>
							<XMLViewer
								xml={
									'<Message Device="23cfcf87-252e-604a-b148-97a572ed8211" OutlookID="OutlookID"><Request Name="UploadGasOBDProcessData"><SSSJL><Row><REALTEMEDATA><Info><SSSJLNR>计算LOAD值</SSSJLNR><SSSJLJG>0.0 </SSSJLJG><SSSJLDW>%</SSSJLDW><SSSJLID>2</SSSJLID></Info><Info><SSSJLNR>发动机RPM</SSSJLNR><SSSJLJG>0</SSSJLJG><SSSJLDW></SSSJLDW><SSSJLID>6</SSSJLID></Info><Info><SSSJLNR>车速传感器</SSSJLNR><SSSJLJG>0 </SSSJLJG><SSSJLDW>km/h</SSSJLDW><SSSJLID>7</SSSJLID></Info><Info><SSSJLNR>大气流量传感器的空气流量</SSSJLNR><SSSJLJG>0.00 </SSSJLJG><SSSJLDW>g/s</SSSJLDW><SSSJLID>8988</SSSJLID></Info><Info><SSSJLNR>绝对油门位置</SSSJLNR><SSSJLJG>100.0 </SSSJLJG><SSSJLDW>%</SSSJLDW><SSSJLID>11</SSSJLID></Info></REALTEMEDATA><FAULTCODE><Info><BREAKCODE>P0365</BREAKCODE><BREAKkDESC>凸轮轴位置传感器B电路（Bank 1）</BREAKkDESC></Info><Info><BREAKCODE>P0505</BREAKCODE><BREAKkDESC>空闲空气控制系统</BREAKkDESC></Info><Info><BREAKCODE>P1720</BREAKCODE><BREAKkDESC>DTC定义不可用, 请访问我们的网站</BREAKkDESC></Info></FAULTCODE></Row></SSSJL></Request></Message>'
								}
							/>
						</Card>
					</Card>
				</Col>
				<Col md={12} sm={24} xs={24}>
					<Card title="Submit RAW data">
						<ul>
							<li>Dev api server: 54.254.225.5</li>
							<li>Staging api server: 34.210.160.172</li>
						</ul>
						<Card type="inner" title="Api v2 new url (08/10/2024)">
							<h4>Api endpoint v2 (support multi type data package):</h4>
							<p>Endpoint for submit rawdata from tools: (required "vin" field in header)</p>
						<ul>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/dev/api/tracking/upload
								</span>
							</li>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/staging/api/tracking/upload
								</span>
							</li>
						</ul>
						</Card>
						<Card type='inner' title='Update JSON GPS data' >
							<h3>update server support json string gps data</h3>
							api endpoint:
							<ul>
								<li>http://locals.innovavietnam.com:4001/v2/jsongps?vin=</li>
								<li>https://locals.innovavietnam.com/v2/jsongps?vin=</li>
							</ul>
							<h2 style={{color: 'red'}}>Local Server was moved to aws</h2>
							<p>Update api simulate carmd service</p>
							Link for submit test:
							<ul>
								<li>outside lab (internet): <span style={{ color: 'red' }}>https://locals.innovavietnam.com</span>[api url]</li>
								<li>inside lab (LAN): <span style={{ color: 'red' }}>http://192.168.1.54:4001</span>[api url] (this server was ofline now)</li>
							</ul>
							api url:
							<ul>
								<li>Service 2.2 - Data Log: <span style={{ color: 'red' }}>/api/tablet/data-log</span></li>
								<li>Service 38 - Get access token: <span style={{ color: 'red' }}>/v1.0/api/auth/token</span></li>
								<li>Service 28 - Register device: <span style={{ color: 'red' }}>/v1.0/api/devices</span></li>
								<li>Service 7 - Create A diagnostic report: <span style={{ color: 'red' }}>/api/reports/</span></li>
								<li>Service 32 - Send Email Report: <span style={{color: 'red'}} >/v1.0/api/reports/send-email</span></li>
							</ul>

							<p>Api v2 now support parse GpsData(2001)</p>
							<p style={{color: 'cyan'}}>update Continuous Integration (CI) & send notification to msTeams!!</p>
						</Card>
						<Card type="inner" title="Api v2">
							<h4>Api endpoint v2 (support multi type data package):</h4>
							<p>Endpoint for submit rawdata from tools:</p>
						<ul>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/send?vin=VIN
								</span>
							</li>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/sendstag?vin=VIN
								</span>
							</li>
						</ul>
						<p>Endpoint for submit rawdata from mobile app:</p>
						<ul>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/msend?vin=VIN
								</span>
							</li>
							<li>
								<span style={{ color: 'red' }}>
									http://locals.innovavietnam.com/v2/msendstag?vin=VIN
								</span>
							</li>
						</ul>
						</Card>
						<br/>
						<p>Endpoint for submit rawdata from tools (old version not support multi type data package):</p>
						<p style={{color: 'red'}}>(old version does not support new feature so we suggest using the new version Api v2 above)</p>
						<ul>
							<li>
								For query readable data from dev server:{' '}
								<span style={{ color: 'blue' }}>
									http://locals.innovavietnam.com/send?vin=VIN
								</span>
							</li>
							<li>
								For query readable data from staging server:{' '}
								<span style={{ color: 'blue' }}>
									http://locals.innovavietnam.com/sendstag?vin=VIN
								</span>
							</li>
						</ul>
						<p>Endpoint for submit rawdata from mobile app:</p>
						<ul>
							<li>
								For query readable data from dev server:{' '}
								<span style={{ color: 'blue' }}>
									http://locals.innovavietnam.com/msend?vin=VIN
								</span>
							</li>
							<li>
								For query readable data from staging server:{' '}
								<span style={{ color: 'blue' }}>
									http://locals.innovavietnam.com/msendstag?vin=VIN
								</span>
							</li>
						</ul>
						<p>
							URL query parameters: ?vin=
							<span style={{ color: 'blue' }}>VIN</span>
							&lang=<span style={{ color: 'blue' }}>LANG</span> if not set
							server will set it to default English
						</p>
						<ul>
							<li>
								<span style={{ color: 'blue' }}>VIN</span>: 17 character VIN not the "VIN word"
							</li>
							<li>
								<span style={{ color: 'blue' }}>LANG</span>: language number
								(English = 1, Spanish = 2, French = 3, Japanese = 4, Chinese =
								5, Vietnamese = 6)
							</li>
						</ul>
					</Card>
				</Col>
			</Row>
		</Layout>
	);
}
