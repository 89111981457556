/*global google*/

import React from 'react';
import {
	Table,
	Divider,
	Button,
	Select,
	Input,
	Tag,
	Typography,
	Form,
	Card,
	Row,
	Col,
	Modal,
	Collapse
} from 'antd';
import { CloudServerOutlined, CarOutlined, MobileOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import ReactJson from 'react-json-view';
import { createApolloFetch } from 'apollo-fetch';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	DirectionsRenderer
} from 'react-google-maps';
import secret from '../config/api_key';
import Map from './gps_helper';
import Layout from './layout';

const { Option } = Select;
// const { TextArea } = Input;
const { Title } = Typography;
const { Panel } = Collapse;

// class Map extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			directions: null
// 		};
// 	}

// 	componentDidMount() {
// 		const directionsService = new google.maps.DirectionsService();

// 		const origin = this.props.origin;
// 		const destination = this.props.destination;
// 		const waypoints = this.props.waypoints;
// 		// const destination = null;

// 		destination &&
// 			directionsService.route(
// 				{
// 					origin: origin,
// 					destination: destination,
// 					travelMode: google.maps.TravelMode.DRIVING,
// 					waypoints: waypoints
// 				},
// 				(result, status) => {
// 					if (status === google.maps.DirectionsStatus.OK) {
// 						this.setState({
// 							directions: result
// 						});
// 					} else {
// 						// console.error(`error fetching directions ${result}`);
// 						console.log('error');
// 					}
// 				}
// 			);
// 	}

// 	// shouldComponentUpdate(nextProps, nextState) {
// 	// 	if (
// 	// 		JSON.stringify(this.props.origin) === JSON.stringify(nextProps.origin)
// 	// 		// this.props.destination === this.nextProps.destination
// 	// 	) {
// 	// 		console.log("props", this.props);
// 	// 		return false;
// 	// 	} else {
// 	// 		console.log("curent props", this.props);
// 	// 		console.log("next props", nextProps);
// 	// 		return true;
// 	// 	}
// 	// }

// 	render() {
// 		const GoogleMapWindow = withGoogleMap(props => (
// 			<GoogleMap
// 				defaultCenter={this.props.origin}
// 				defaultZoom={13}
// 				options={{ streetViewControl: false }}
// 			>
// 				{this.state.directions ? (
// 					<DirectionsRenderer directions={this.state.directions} />
// 				) : (
// 					<Marker
// 						position={this.props.origin}
// 						label={{ text: 'S', color: 'white' }}
// 					/>
// 				)}
// 			</GoogleMap>
// 		));

// 		return (
// 			<div>
// 				<GoogleMapWindow
// 					containerElement={<div style={{ height: `600px`, width: '100%' }} />}
// 					mapElement={<div style={{ height: `100%` }} />}
// 				/>
// 			</div>
// 		);
// 	}
// }

async function fetch_api(vin, deviceId, server) {
	const fetch = createApolloFetch({
		// uri: `http://${server}:4000/graphql`
		uri: `https://${server}.innovavietnam.com/graphql`
	});
	await fetch({
		query: `
			query getTrip($vin: String!, $deviceId: String!) {
        getTrip(vin: $vin, deviceId: $deviceId) {
				  vin
    			deviceId
    			isTripCompleted
    			startJson
    			endJson
    			pids
    			vehicle
    			freezeframe
    			monitor_icons
    			dtcs
        }
			}
		`,
		variables: {
			vin: vin,
			deviceId: deviceId
		}
	}).then(res => {
		// let apiRes = res.data;
		console.log('res data: ', JSON.stringify(res));
		res.errors && console.log('Err:', res.errors);
		console.log(res.data);
		resdata = res.data;
		try {
			resdata.getTrip.startJson = JSON.parse(resdata.getTrip.startJson);
			resdata.getTrip.endJson = JSON.parse(resdata.getTrip.endJson);
			resdata.getTrip.pids = JSON.parse(resdata.getTrip.pids);
			resdata.getTrip.vehicle = JSON.parse(resdata.getTrip.vehicle);
			resdata.getTrip.freezeframe = JSON.parse(resdata.getTrip.freezeframe);
			resdata.getTrip.monitor_icons = JSON.parse(resdata.getTrip.monitor_icons);
			resdata.getTrip.dtcs = JSON.parse(resdata.getTrip.dtcs);
			let { getTrip } = res.data;
			let { startJson, endJson } = getTrip;
			const start = startJson ? startJson : {};
			const end = endJson ? endJson : {};
			gps_data = { start, end };
		} catch (error) {
			gps_data = null;
		}
	});
}
let resdata;
let gps_data;

class Gps_demo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Server: 'dev',
			loading: false,
			vin: '1FM5K8HT4JGA75690',
			deviceId: 'ec32c795-b1a8-4cd6-9d09-d360e0dc0de6',
			data: '',
			mapData: {},
			exampleMap: false,
			toggleForm: true,
			modalActive: false,
			modal_data: '',
			modal_map: undefined
		};
	}

	handleClear() {
		this.setState({
			Server: 'dev',
			loading: false,
			// vin: '',
			// deviceId: '',
			data: '',
			mapData: {},
			toggleForm: true,
			modalActive: false,
			modal_data: '',
			modal_map: undefined
		});
	}

	handleChange = value => {
		this.setState({ Server: value });
	};
	handleVin(event) {
		this.setState({ vin: event.target.value });
	}
	handleDeviceId(event) {
		this.setState({ deviceId: event.target.value });
	}

	async handleClick(e) {
		e.preventDefault();
		const { vin, deviceId } = this.state;
		localStorage.setItem('vin', vin);
		localStorage.setItem('deviceId', deviceId);

		this.setState({ loading: true });
		await fetch_api(this.state.vin, this.state.deviceId, this.state.Server);
		await this.setState({ loading: false });
		let data = resdata;
		let gps_ = gps_data;
		let origin = null;
		let destination;
		let waypoints = [];

		console.log('gps_', gps_);
		if (gps_ !== null) {
			waypoints = gps_.start.gps
				? gps_.start.gps.map(p => ({
						location: { lat: p.latitude, lng: p.longitude },
						stopover: true
				  }))
				: [];

			if (waypoints.length !== 0) {
				origin = waypoints.shift().location;
				if (!gps_.end.gps) {
					try {
						destination = waypoints.pop().location;
					} catch (err) {}
				} else {
					destination = {
						lat: gps_.end.gps[0].latitude,
						lng: gps_.end.gps[0].longitude
					};
				}
			}
		}

		console.log('des: ', destination);
		console.log('waypoints', waypoints);

		this.setState({
			data: data,
			mapData: { origin, destination, waypoints },
			toggleForm: false
			// exampleMap: true
		});
	}
	closeModal = () => {
		this.setState({
			modalActive: false,
			modal_map: undefined
		});
	};
	async componentDidMount() {
		let vin, deviceId;
		await (async () => {
			const local_vin = await localStorage.getItem('vin');
			const local_deviceId = await localStorage.getItem('deviceId');
			vin = local_vin ? local_vin : '1FM5K8HT4JGA75690';
			deviceId = local_deviceId
				? local_deviceId
				: 'ec32c795-b1a8-4cd6-9d09-d360e0dc0de6';
		})();

		// console.log('componentDidMount', vin, deviceId);
		this.setState({
			vin: vin,
			deviceId: deviceId
		});
		// console.log('componentDidMount', this.state.vin, this.state.deviceId);
		// this.setState({ state: this.state });
	}

	// loop interval
	// componentDidMount() {
	// 	this.interval = setInterval(async () => {
	// 		if (!this.state.toggleForm) {
	// 			await fetch_api(this.state.vin, this.state.deviceId, this.state.Server);
	// 			let data = resdata;
	// 			let gps_ = gps_data;
	// 			let origin = null;
	// 			let destination;

	// 			console.log("gps_", gps_);
	// 			const waypoints = gps_
	// 				? gps_.start.gps.map(p => ({
	// 						location: { lat: p.latitude, lng: p.longitude },
	// 						stopover: true
	// 				  }))
	// 				: [];

	// 			if (gps_) {
	// 				origin = waypoints.shift().location;
	// 				if (!gps_.end.gps) {
	// 					try {
	// 						destination = waypoints.pop().location;
	// 					} catch (err) {}
	// 				} else {
	// 					destination = {
	// 						lat: gps_.end.gps[0].latitude,
	// 						lng: gps_.end.gps[0].longitude
	// 					};
	// 				}
	// 			}

	// 			console.log("des: ", destination);
	// 			console.log("waypoints", waypoints);

	// 			this.setState({
	// 				data: data,
	// 				mapData: { origin, destination, waypoints }
	// 				// toggleForm: false
	// 				// exampleMap: true
	// 			});
	// 		}
	// 	}, 8000);
	// }

	shouldComponentUpdate(nextProps, nextState) {
		if (JSON.stringify(this.state) === JSON.stringify(nextState)) {
			console.log('state', this.state);
			return false;
		} else {
			console.log('curent state', this.state);
			console.log('next state', nextState);
			return true;
		}
	}

	componentDidUpdate() {
		console.log('server:', this.state.Server);
		console.log('data:', this.state.data);
		console.log('vin:', this.state.vin);
		console.log('deviceId', this.state.deviceId);
		console.log('mapData', this.state.mapData);
		console.log('modal', this.state.modal_data);
	}
	componentWillUnmount() {
		// clearInterval(this.interval);
	}

	render() {
		const MapLoader = withScriptjs(Map);

		let rowformstyle = {
			margin: 10,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end'
		};

		let data = this.state.data.getTrip
			? [
					{
						vin: this.state.data.getTrip.vin,
						deviceId: this.state.data.getTrip.deviceId,
						isTripCompleted: this.state.data.getTrip.isTripCompleted
							? 'true'
							: 'false',
						GPS: {
							start: this.state.data.getTrip.startJson,
							end: this.state.data.getTrip.endJson
						},
						pids: { pids: this.state.data.getTrip.pids },
						vehicle: { vehicle: this.state.data.getTrip.vehicle },
						freezeframe: { freezeframe: this.state.data.getTrip.freezeframe },
						monitor_icons: {
							monitor_icons: this.state.data.getTrip.monitor_icons
						},
						dtcs: { dtcs: this.state.data.getTrip.dtcs }
					}
			  ]
			: null;
		console.log('viewdata', data);
		function validatingJSON(json) {
			var checkedjson;
			try {
				checkedjson = JSON.parse(json);
			} catch (e) {}
			return checkedjson;
		}
		let columns = [
			{
				title: 'VIN',
				dataIndex: 'vin',
				key: 'vin',
				width: '15%'
			},
			{
				title: 'DeviceId',
				dataIndex: 'deviceId',
				key: 'deviceId',
				width: '20%',
				sorter: (a, b) => (a.deviceID > b.deviceID ? 1 : -1),
				sortDirections: ['descend', 'ascend']
			},
			{
				title: 'isTripCompleted',
				dataIndex: 'isTripCompleted',
				key: 'isTripCompleted',
				width: '15%',
				render: text => (
					<>
						{text === 'false' ? (
							<Tag color={'red'}>{text}</Tag>
						) : (
							<Tag color={'green'}>{text}</Tag>
						)}
						<Divider type="vertical" />
						<Button
							type="primary"
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_map: true
								});
							}}
						>
							viewMap
						</Button>
					</>
				)
			},
			{
				title: 'GPS Data',
				dataIndex: 'GPS',
				key: 'gps',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			},
			{
				title: 'PIDS',
				dataIndex: 'pids',
				key: 'pids',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			},
			{
				title: 'VEHICLE',
				dataIndex: 'vehicle',
				key: 'vehicle',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			},
			{
				title: 'freezeframe',
				dataIndex: 'freezeframe',
				key: 'freezeframe',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			},
			{
				title: 'monitor_icons',
				dataIndex: 'monitor_icons',
				key: 'monitor_icons',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			},
			{
				title: 'dtcs',
				dataIndex: 'dtcs',
				key: 'dtcs',
				width: '10%',
				render: text => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text
								});
							}}
						>
							view data
						</Button>
					</div>
				)
			}
		];

		return (
			<Layout selectedKeys={['gpsdemo']}>
				<Modal
					title="Detail"
					visible={this.state.modalActive}
					onCancel={this.closeModal}
					style={{ top: 30 }}
					width="700px"
					bodyStyle={{
						maxHeight: '80vh',
						overflow: 'scroll'
						// padding: 5
					}}
					footer={[
						<Button key="back" onClick={this.closeModal}>
							Return
						</Button>
					]}
				>
					{this.state.mapData.origin && this.state.modal_map && (
						// <Card title="Map report" style={{ margin: 10 }}>
						<MapLoader
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secret.gmap_api_key}&v=3.exp&libraries=geometry,drawing,places`}
							loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
							origin={this.state.mapData.origin}
							destination={this.state.mapData.destination}
							waypoints={this.state.mapData.waypoints}
						/>
						// </Card>
					)}
					{!this.state.modal_map && (
						<ReactJson
							src={this.state.modal_data}
							indentWidth={0}
							name={false}
							displayDataTypes={false}
						/>
					)}
				</Modal>
				{!this.state.toggleForm && (
					<Button
						style={{ margin: 10 }}
						type="danger"
						onClick={this.handleClear.bind(this)}
					>
						Clear
					</Button>
				)}
				{this.state.toggleForm && (
					<div
						style={{
							padding: 10,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<Title level={4}>
						<CloudServerOutlined/> <Divider type="vertical" />
							Server
						</Title>
						<Select
							defaultValue="dev"
							style={{ width: 120 }}
							onChange={this.handleChange}
						>
							<Option value="dev">Dev</Option>
							<Option value="stag">Staging</Option>
						</Select>
						<Divider></Divider>
						<Tag color="red" style={{ marginBottom: 10 }}>
							<a href="/gpsexample">Click to view gps example</a>
						</Tag>
						<Form onSubmit={this.handleClick.bind(this)}>
							<Card>
								<Row style={rowformstyle}>
									<div>
										<CarOutlined /> <Divider type="vertical" />
										VIN :
									</div>
									<Input
										style={{ width: 200 }}
										placeholder="vin"
										onChange={this.handleVin.bind(this)}
										defaultValue={localStorage.getItem('vin')}
										required
									/>
								</Row>
								<Row style={rowformstyle}>
									<div>
										<MobileOutlined /> <Divider type="vertical" />
										DeviceId :
										<Input
											style={{ width: 200 }}
											placeholder="deviceId"
											onChange={this.handleDeviceId.bind(this)}
											defaultValue={localStorage.getItem('deviceId')}
											required
										/>
									</div>
								</Row>
								<Row style={rowformstyle}>
									<Button
										type="primary"
										htmlType="submit"
										// onClick={this.handleClick.bind(this)}
									>
										Search
									</Button>
								</Row>
							</Card>
						</Form>
					</div>
				)}
				{/* {this.state.exampleMap && (
					<Card title="example with mockupdata" style={{ margin: 10 }}>
						<MapLoader
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secret.gmap_api_key}&v=3.exp&libraries=geometry,drawing,places`}
							loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
							// origin={this.state.mapData.origin}
							// destination={this.state.mapData.destination}
							// waypoints={this.state.mapData.waypoints}
							// mock data
							origin={{ lat: 10.805029, lng: 106.642711 }}
							destination={{ lat: 10.802152, lng: 106.641101 }}
							waypoints={[
								{ location: { lat: 10.803048, lng: 106.64241 }, stopover: true }
							]}
						/>
					</Card>
				)} */}
				{/* {this.state.mapData.origin && (
					<Card title="Map report" style={{ margin: 10 }}>
						<MapLoader
							googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${secret.gmap_api_key}&v=3.exp&libraries=geometry,drawing,places`}
							loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
							origin={this.state.mapData.origin}
							destination={this.state.mapData.destination}
							waypoints={this.state.mapData.waypoints}
							// mock data
							// origin={{ lat: 10.805029, lng: 106.642711 }}
							// destination={{ lat: 10.802152, lng: 106.641101 }}
							// waypoints={[
							// 	{ location: { lat: 10.803048, lng: 106.64241 }, stopover: true }
							// ]}
						/>
					</Card>
				)} */}
				{data !== null && (
					<Table
						columns={columns}
						dataSource={data}
						size="middle"
						bordered
						loading={this.state.loading}
						// title="report"
						// rowKey={}
						pagination={{ pageSize: 50 }}
					/>
				)}
				{this.state.data !== '' && (
					<Collapse>
						<Panel header="Full Report" key="1">
							<Card
								title="Report Detail"
								style={{ overflow: 'scroll', margin: 10 }}
							>
								{/* {JSON.stringify(this.state.data)} */}
								<ReactJson
									src={this.state.data}
									indentWidth={0}
									name={false}
									displayDataTypes={false}
								/>
							</Card>
						</Panel>
					</Collapse>
				)}
			</Layout>
		);
	}
}

export default Gps_demo