import React from "react";
import axios from "axios";
import URL from "../config/config";
// import "./App.css";
import {
	Table,
	Input,
	Modal,
	Button,
	Divider,
	Icon,
	Tag,
	Card,
	Radio
	// Divider, Tag, Pagination, Input, Button, Icon
} from "antd";
import "antd/dist/antd.css";
import { LinkOutlined } from "@ant-design/icons";
// import Highlighter from "react-highlight-words";
import ReactJson from "react-json-view";
import timeConvert from "../helper/data_convert";
import Layout from './layout';
import { Link } from "react-router-dom";
import TableTitle from './table_component/title';
import { useState, useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';

const { Search } = Input;

export default class RawData extends React.Component {
	constructor() {
		super();
		this.state = {
			datas: [],
			loading: false,
			searchText: "",
			search_data: [],
			modalActive: false,
			modal_data: "",
			modal_prettify: true,
			api_ip: ""
		};
	}
	componentDidMount() {}
	getDatas() {
		// let url = "http://localhost:4004/data";
		let url = URL.rawData;
		// let url = "http://118.69.35.198:4001/data";

		this.setState({
			loading: true
		});
		axios.get(url).then(response => {
			console.log(response);
			this.setState({
				datas: response.data,
				loading: false
			});
			// console.log(this.state.datas);
		});
	}
	componentWillMount() {
		let url = URL.rawApi;
		axios.get(url).then(response => {
			console.log(response.data);
			let server_ip =
				response.data.current !== null
					? response.data.current.api_server_ip
					: response.data.default.api_server_ip;
			this.setState({
				api_ip: server_ip
			});
		});
		this.getDatas();
	}

	componentDidUpdate() {
		console.log("server ip:", this.state.api_ip);
	}
	// Search by Keyword
	handleChange(event) {
		this.setState({ searchText: event.target.value });
	}
	handleSearch(value) {
		this.setState({ searchText: value });
	}

	handleServerChange(e) {
		let url = URL.rawApi;
		axios
			.post(
				url,
				{ api_server_ip: e.target.value },
				{ headers: { "Content-Type": "application/json" } }
			)
			.then(res => console.log(res));
		this.setState({ api_ip: e.target.value });
	}

	closeModal = () => {
		this.setState({
			modalActive: false
		});
	};
	//############# handle search
	// getColumnSearchProps = dataIndex => ({
	// 	filterDropdown: ({
	// 		setSelectedKeys,
	// 		selectedKeys,
	// 		confirm,
	// 		clearFilters
	// 	}) => (
	// 		<div style={{ padding: 8 }}>
	// 			<Input
	// 				ref={node => {
	// 					this.searchInput = node;
	// 				}}
	// 				placeholder={`Search ${dataIndex}`}
	// 				value={selectedKeys[0]}
	// 				onChange={e =>
	// 					setSelectedKeys(e.target.value ? [e.target.value] : [])
	// 				}
	// 				onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
	// 				style={{ width: 188, marginBottom: 8, display: "block" }}
	// 			/>
	// 			<Button
	// 				type="primary"
	// 				onClick={() => this.handleSearch(selectedKeys, confirm)}
	// 				icon="search"
	// 				size="small"
	// 				style={{ width: 90, marginRight: 8 }}
	// 			>
	// 				Search
	// 			</Button>
	// 			<Button
	// 				onClick={() => this.handleReset(clearFilters)}
	// 				size="small"
	// 				style={{ width: 90 }}
	// 			>
	// 				Reset
	// 			</Button>
	// 		</div>
	// 	),
	// 	filterIcon: filtered => (
	// 		<Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
	// 	),
	// 	onFilter: (value, record) =>
	// 		record[dataIndex]
	// 			.toString()
	// 			.toLowerCase()
	// 			.includes(value.toLowerCase()),
	// 	onFilterDropdownVisibleChange: visible => {
	// 		if (visible) {
	// 			setTimeout(() => this.searchInput.select());
	// 		}
	// 	},
	// 	render: text => (
	// 		<Highlighter
	// 			highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
	// 			searchWords={[this.state.searchText]}
	// 			autoEscape
	// 			textToHighlight={text.toString()}
	// 		/>
	// 	)
	// });

	// handleSearch = (selectedKeys, confirm) => {
	// 	confirm();
	// 	this.setState({ searchText: selectedKeys[0] });
	// };

	// handleReset = clearFilters => {
	// 	clearFilters();
	// 	this.setState({ searchText: "" });
	// };

	render() {
		// let data = this.state.datas.map(data => ({
		// 	key: data._id,
		// 	vin: data.vin,
		// 	payLoad: data.payLoad,
		// 	rawData: data.rawData,
		// 	readableData: data.readableData,
		// 	date: data.createdAt
		// }));
		// let data = this.state.datas.map(data =>
		// 	data.vin
		// 		? {
		// 				key: data._id,
		// 				vin: data.vin,
		// 				payLoad: data.payLoad,
		// 				rawData: data.rawData,
		// 				readableData: data.readableData,
		// 				date: data.createdAt
		// 		  }
		// 		: {
		// 				key: data._id,
		// 				vin: " ",
		// 				payLoad: data.payLoad,
		// 				rawData: data.rawData,
		// 				readableData: data.readableData,
		// 				date: data.createdAt
		// 		  }
		// );

		function validatingJSON(json) {
			var checkedjson;
			try {
				checkedjson = JSON.parse(json);
			} catch (e) {}
			return checkedjson;
		}

		// implement search
		function searchingFor(searchText) {
			return function(x) {
				return (
					x.vin.toLowerCase().includes(searchText.toLowerCase()) || !searchText
				);
			};
		}

		let filterData = this.state.datas
			.filter(searchingFor(this.state.searchText))
			.map(data =>
				// key: data.key,
				// vin: data.vin,
				// payLoad: data.payLoad,
				// rawData: data.rawData,
				// readableData: data.readableData,
				// date: data.date
				data.vin
					? {
							key: data._id,
							vin: data.vin,
							deviceID: data.deviceID ? data.deviceID : " ",
							payLoad: data.payLoad,
							rawData: data.rawData,
							readableData: data.readableData,
							date: timeConvert(data.createdAt)
					  }
					: {
							key: data._id,
							vin: " ",
							deviceID: data.deviceID ? data.deviceID : " ",
							payLoad: data.payLoad,
							rawData: data.rawData,
							readableData: data.readableData,
							date: timeConvert(data.createdAt)
					  }
			);
		console.log("filter: ", filterData, filterData.length);
		// filterData.length && console.log("filter true");

		const columns = [
			{
				title: 'Id',
				dataIndex: 'key',
				key: 'key',
				width: 80,
				render: (text, record) => (
					<Link to={`/datapackage?id=${text}`} target='_blank' >detail <LinkOutlined/></Link>
				)
			},
			{
				title: 'Date Create',
				dataIndex: 'date',
				key: 'date',
				width: 180,
				render: (text, record) => (
					// <Link to={`/datapackage?id=${record.key}`} target='_blank' >{text} <LinkOutlined/></Link>
				<>{text}</>
				)
				// sorter: (a, b) => a.date - b.date,
				// sortDirections: ["descend", "ascend"]
			},
			{
				title: 'vin',
				dataIndex: 'vin',
				key: 'vin',
				width: 200,
				// ...this.getColumnSearchProps("address"),
				// sorter: (a, b) => a.vin - b.vin,
				// sortDirections: ["descend", "ascend"]
			},
			{
				title: 'deviceID',
				dataIndex: 'deviceID',
				key: 'deviceID',
				width: 300,
				// ...this.getColumnSearchProps("address"),
				// sorter: (a, b) => a.vin - b.vin,
				// sortDirections: ["descend", "ascend"]
			},
			{
				title: 'readableData',
				dataIndex: 'readableData',
				key: 'readableData',
				width: 120,
				render: (text) => (
					<div
						style={{
							// color: "#ffcc00",
							overflowX: 'scroll',
						}}
					>
						{text && !text.includes('Data type does not support yet!') ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Button
									onClick={() => {
										this.setState({
											modalActive: true,
											modal_data: text,
										});
									}}
								>
									{/* Data */}
									{JSON.parse(text).report === null ? (
										<div>
											<Divider type="vertical" />
											{/* <span style={{ color: "red" }}>NULL</span> */}
											<Tag color={'red'}>NULL</Tag>
										</div>
									) : (
										<>Data</>
									)}
								</Button>
							</div>
						) : (
							<p>{text}</p>
						)}
					</div>
				),
			},
			{
				title: 'rawData',
				dataIndex: 'rawData',
				key: 'rawData',
				width: 600,
				render: (text) => (
					// <div
					// 	style={{
					// 		// color: "#00ef00",
					// 		overflowX: "scroll",
					// 		// width: 400,
					// 		maxWidth: 400,
					// 		height: 200
					// 		// backgroundColor: "#000"
					// 	}}
					// >
					// 	<ReactJson
					// 		src={JSON.parse(text)}
					// 		indentWidth={0}
					// 		name={false}
					// 		displayDataTypes={false}
					// 	/>
					// </div>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							// gap: 5,
							justifyContent: 'flex-end',
							alignItems: 'center',
							overflow: 'scroll',
							// maxWidth: 400,
							maxHeight: 60,
						}}
					>
						{JSON.parse(text)['api_server_ip'] === '34.210.160.172' ? (
							<Tag color={'green'}>Stag</Tag>
						) : (
							<Tag color={'volcano'}>Dev</Tag>
						)}
						{JSON.parse(text)['data_type'] ? (
							<Tag color={'blue'}>{JSON.parse(text)['data_type']}</Tag>
						) : (
							JSON.parse(text).rawExtracted.map((a) => {
								// let typelink = a.data_type.includes('unknow') ? 'unknow Type' : a.data_type;
								return (
								// <Tag color={'blue'}><Link to={`/rawdatabytype?type=${typelink}`} target='_blank' >{a.data_type} <LinkOutlined/></Link></Tag>
								<Tag color={'cyan'}>{a.data_type}</Tag>
							)})
						)}
						{/* <Divider type="vertical" /> */}
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									modal_data: text,
								});
							}}
						>
							Data
						</Button>
					</div>
				),
			},
			{
				title: 'payload from tool',
				dataIndex: 'payLoad',
				key: 'payLoad',
				width: 400,
				render: (text) => (
					<div
						style={{
							color: '#ef00ef',
							overflowX: 'scroll',
							// width: "90%",
							maxWidth: 400,
							maxHeight: 60,
						}}
					>
						{text}
					</div>
				),
			},
		];

		// console.log(this.state.datas.map(data => console.log(data.readableData)));
		// let readableD = data.map(data => data.readableData);
		// console.log(readableD);
		// console.log(data.map(data => JSON.parse(data.readableData)));

		// console.log(data);
		return (
			<Layout selectedKeys={['rawdata']}>
				{/* <Search
					placeholder="input search by vin"
					// onSearch={e => this.handleSearch(e)}
					onChange={this.handleSearch.bind(this)}
					style={{ width: 400 }}
					size="large"
				/> */}
				{/* <Card> */}
				{/* <p>
						API Server ip: <Tag color="purple">{this.state.api_ip}</Tag>
					</p> */}
				{/* <Radio.Group
						value={this.state.api_ip}
						onChange={this.handleServerChange.bind(this)}
					>
						<Radio.Button value="54.254.225.5">Dev</Radio.Button>
						<Radio.Button value="34.210.160.172">Staging</Radio.Button>
					</Radio.Group> */}
				{/* </Card> */}
				<Modal
					title="Detail"
					visible={this.state.modalActive}
					onCancel={this.closeModal}
					style={{ top: 30 }}
					width="700px"
					bodyStyle={{
						maxHeight: '80vh',
						overflow: 'scroll',
						// padding: 5
					}}
					footer={[
						<Button key="back" onClick={this.closeModal}>
							Return
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={() => {
								this.setState({ modal_prettify: !this.state.modal_prettify });
							}}
							// icon="code"
						>
							{this.state._prettify ? (
								<span>String</span>
							) : (
								<span>Prettify</span>
							)}
						</Button>,
					]}
				>
					{this.state.modal_prettify ? (
						validatingJSON(this.state.modal_data) ? (
							<ReactJson
								src={JSON.parse(this.state.modal_data)}
								indentWidth={0}
								name={false}
								displayDataTypes={false}
							/>
						) : (
							<span>Invalid object!</span>
						)
					) : (
						this.state.modal_data
					)}
				</Modal>
				<VirtualTable
					columns={columns}
					dataSource={filterData}
					rowKey={(record) => record.key}
					size="middle"
					bordered
					loading={this.state.loading}
					title={() => (
						<>
							<h3>1000 latest row</h3>
							<TableTitle />
							<Divider />
							<Search
								placeholder="input search by vin"
								onSearch={(value) => this.handleSearch(value)}
								// onChange={this.handleChange.bind(this)}
								style={{ width: 400 }}
								size="large"
							/>
						</>
					)}
					scroll={{
						y: 800,
						x: '100vw',
					}}
				/>
				{/* <Table
					columns={columns}
					dataSource={filterData}
					rowKey={(record) => record.key}
					size="middle"
					bordered
					loading={this.state.loading}
					title={() => (
						<>
							<h3>1000 latest row</h3>
							<TableTitle />
							<Divider />
							<Search
								placeholder="input search by vin"
								onSearch={(value) => this.handleSearch(value)}
								// onChange={this.handleChange.bind(this)}
								style={{ width: 400 }}
								size="large"
							/>
						</>
					)}
					pagination={{ pageSize: 50 }}
					scroll={{ y: '75vh' }}
				/> */}
			</Layout>
		);
	}
}

function VirtualTable(props) {
  const { columns, scroll } = props;
  const [tableWidth, setTableWidth] = useState(0);
  const widthColumnCount = columns.filter(({ width }) => !width).length;
  const mergedColumns = columns.map((column) => {
    if (column.width) {
      return column;
    }

    return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
  });
  const gridRef = useRef();
  const [connectObject] = useState(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => null,
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });
    return obj;
  });

  const resetVirtualGrid = () => {
    gridRef.current.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: false,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject;
    const totalHeight = rawData.length * 60;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={mergedColumns.length}
        columnWidth={(index) => {
          const { width } = mergedColumns[index];
          return totalHeight > scroll.y && index === mergedColumns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={scroll.y}
        rowCount={rawData.length}
        rowHeight={() => 60}
        width={tableWidth}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          let content = rawData[rowIndex][mergedColumns[columnIndex].dataIndex];
          if ('render' in mergedColumns[columnIndex]) {
            content = mergedColumns[columnIndex].render(content);
          }
          return (
            <div
              className={classNames("virtual-table-cell", {
                "virtual-table-cell-last":
                  columnIndex === mergedColumns.length - 1
              })}
              style={style}
            >
              {content}
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <Table
        {...props}
        className="virtual-table"
        columns={mergedColumns}
        pagination={false}
        components={{
          body: renderVirtualList,
        }}
      />
    </ResizeObserver>
  );
}