import React, { useState } from 'react';
import URL from '../config/config';
import {
	Card,
	Row,
	Button,
	Alert,
	Form,
	Input,
	Select,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import XMLViewer from 'react-xml-viewer';
import ReactJson from 'react-json-view';
import Layout from './layout';
import axios from 'axios';

export default function HexStringTest() {
	const layout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 },
	};
	const tailLayout = {
		wrapperCol: { offset: 4, span: 16 },
	};

	const [form] = Form.useForm();
	const [message, setMessage] = useState();
	const [err, setErr] = useState();

	const onFinish = (values) => {
		const url = {
			dev:
				'https://locals.innovavietnam.com/v2/hextest?vin=hexstringtest&lang=1',
			stag:
				'https://locals.innovavietnam.com/v2/hexteststag?vin=hexstringtest&lang=1',
		};
		console.log('Success:', values);
		// console.log(url[values.server])
		axios
			.post(url[values.server], values.payload, {
				headers: {
					'x-innova-device': 'submit-from-web',
				},
			})
			.then((res) => {
				console.log(res);
				setMessage(res.data);
				// setTimeout(setMessage(), 5000)
			})
			.catch((err) => {
				console.log(err);
				setErr(err);
			});
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<Layout selectedKeys={['hexstring']}>
			{message && <Alert message={JSON.stringify(message)} type="success" />}
			{err && <Alert message={JSON.stringify(err)} type="error" />}

			<Form
				{...layout}
				name="basic"
				form={form}
				initialValues={{ server: 'dev' }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					label="Server"
					name="server"
					rules={[{ required: true, message: 'Please input your server!' }]}
				>
					<Select>
						<Select.Option value="dev">dev</Select.Option>
						<Select.Option value="stag">stag</Select.Option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Hex String payload"
					name="payload"
					rules={[{ required: true, message: 'Please input your payload!' }]}
				>
					<Input.TextArea />
				</Form.Item>

				<Form.Item
				// {...tailLayout}
				>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>{' '}
					<Button
						onClick={() => {
							form.resetFields();
							setMessage();
							setErr();
						}}
					>
						clear
					</Button>
				</Form.Item>
			</Form>
		</Layout>
	);
}
