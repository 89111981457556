import React from "react";
import ReactDOM from "react-dom";
import "dotenv/config";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import RawData from "./components/rawData";
import XMLData from "./components/xmlReport";
import Header from "./components/header_nav/header";
import * as serviceWorker from "./serviceWorker";
import GetProfile from "./components/getProfile_tools";
import Gps_demo from "./components/gps_demo";
import Gps_example from "./components/gps_example";
import SearchRawData from "./components/searchRawData";
import Doc from "./components/doc";
import DataPackage from "./components/dataPackage";
import RawDataByType from "./components/rawDataByType";
import HexStringTest from "./components/hexStringTest";
import DataByDevice from "./components/databydevice";

const routing = (
	<Router>
		{/* <Header /> */}
		<Switch>
			<Route exact path="/" component={App} />
			<Route exact path="/rawdata" component={RawData} />
			<Route exact path="/searchrawdata" component={SearchRawData} />
			<Route exact path="/xmldata" component={XMLData} />
			<Route exact path="/getprofile" component={GetProfile} />
			<Route exact path="/gpsdemo" component={Gps_demo} />
			<Route exact path="/gpsexample" component={Gps_example} />
			<Route exact path="/doc" component={Doc} />
			<Route exact path="/datapackage" component={DataPackage} />
			<Route exact path="/rawdatabytype" component={RawDataByType} />
			<Route exact path="/hexstring" component={HexStringTest} />
			<Route exact path="/databydevice" component={DataByDevice} />
		</Switch>
	</Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
