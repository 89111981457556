import Title from "antd/lib/skeleton/Title";
import React from "react"
import {
	Table,
	Input,
	Modal,
	Button,
	Divider,
	Icon,
	Tag,
	Card,
	DatePicker,
	Form,
	Checkbox,
} from 'antd';
import { useLocation, Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

export default function TableTitle() {
  const typeList = [
    {
      name: 'VinProfile',
      code: 1,
    },
    {
      name: 'MonitorStatus',
      code: 11,
    },
    {
      name: 'FreezeFrame',
      code: 112,
    },
    {
      name: 'Dtc',
      code: 13,
    },
    {
      name: 'VehicleInfo',
      code: 15,
    },
    {
      name: 'RecordedLiveData',
      code: 16,
    },
    {
      name: 'OemData',
      code: 51,
    },
    {
      name: 'OBD2 LiveData PID Value',
      code: 5,
    },
    {
      name: 'OEM LiveData',
      code: 1004,
    },
    {
      name: 'ServiceCheck',
      code: 7000,
    },
    // {
    //   name: 'Mod_568',
    //   code: [17, 18, 19]
    // },
    {
      name: 'OBD2 Extra Data',
      code: 20,
    },
    {
      name: 'GpsData',
      code: 2001,
    },
    {
      name: 'unknow Type',
    },
  ]

  return (
    <>
      {typeList.map(a => {
        return <Tag color={'blue'}><Link to={`/rawdatabytype?type=${a.name}`} target='_blank' >{a.name} <LinkOutlined/></Link></Tag>
      })}
    </>
  )
}