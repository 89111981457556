import React from "react";
import axios from "axios";
import URL from "../config/config";
import {
	Table,
	Divider,
	Button,
	Modal,
	Icon
	// Divider, Tag, Pagination, Input, Button, Icon
} from "antd";
import "antd/dist/antd.css";
// import Highlighter from "react-highlight-words";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import timeConvert from "../helper/data_convert";
import Layout from './layout';

export default class XMLReport extends React.Component {
	constructor() {
		super();
		this.state = {
			datas: [],
			loading: false,
			searchText: "",
			search_data: [],
			_prettify: true,
			modalActive: false,
			quickViewReport: "",
			dataType: ""
			// readableData: false,
			// base64_payload: false
		};
	}

	getDatas() {
		let url = URL.xmlData;

		this.setState({
			loading: true
		});
		axios.get(url).then(response => {
			console.log(response);
			this.setState({
				datas: response.data,
				loading: false
			});
			// console.log(this.state.datas);
		});
	}
	componentWillMount() {
		this.getDatas();
	}
	// Search by Keyword
	handleChange(event) {
		this.setState({ searchText: event.target.value });
	}
	handleSearch(value) {
		this.setState({ searchText: value });
	}

	// Open Modal
	// openModal(report) {
	// 	this.setState({
	// 		quickViewReport: report,
	// 		modalActive: true
	// 	});
	// }
	// Close Modal
	closeModal = () => {
		this.setState({
			modalActive: false,
			_prettify: true
			// base64_payload: false
			// readableData: false
		});
	};

	render() {
		function searchingFor(searchText) {
			return function(x) {
				return (
					x.vin.toLowerCase().includes(searchText.toLowerCase()) || !searchText
				);
			};
		}
		// search filter go here

		let data = this.state.datas.map(data => ({
			key: data._id,
			deviceID: data.deviceID ? data.deviceID : " ",
			xml_payload: data.xml_payload,
			readableData: data.readableData,
			base64_payload: data.base64_payload,
			date: timeConvert(data.createdAt)
		}));

		const columns = [
			{
				title: "Date Create",
				dataIndex: "date",
				key: "date",
				width: "15%",
				sorter: (a, b) => {
					let _a = new Date(a.date);
					let _b = new Date(b.date);
					return _a > _b ? 1 : -1;
				},
				sortDirections: ["descend", "ascend"]
			},
			// {
			// 	title: "vin",
			// 	dataIndex: "vin",
			// 	key: "vin",
			// 	width: "15%"
			// 	// ...this.getColumnSearchProps("address"),
			// 	// sorter: (a, b) => a.vin - b.vin,
			// 	// sortDirections: ["descend", "ascend"]
			// },
			{
				title: "Device ID",
				dataIndex: "deviceID",
				key: "deviceID",
				width: "20%",
				// ...this.getColumnSearchProps("address"),
				sorter: (a, b) => (a.deviceID > b.deviceID ? 1 : -1),
				sortDirections: ["descend", "ascend"]
			},
			{
				title: "Action",
				// dataIndex: "xml_payload",
				key: "action",
				width: "15%",
				render: (text, record) => (
					<div>
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									quickViewReport: record.xml_payload,
									// readableData: false
									dataType: "xml"
								});
							}}
						>
							Report
						</Button>
						<Divider type="vertical" />
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									quickViewReport: record.readableData,
									dataType: "readable"
									// readableData: true
								});
							}}
						>
							readableData
						</Button>
						<Divider type="vertical" />
						<Button
							onClick={() => {
								this.setState({
									modalActive: true,
									quickViewReport: record.base64_payload,
									dataType: "base64"
									// base64_payload: true
								});
							}}
						>
							base64 payload
						</Button>
						{/* {record.xml_payload} */}
					</div>
				)
			}
			// {
			// 	title: "readableData",
			// 	dataIndex: "readableData",
			// 	key: "readableData",
			// 	width: "40%",
			// 	render: text => (
			// 		<div
			// 			style={{
			// 				// color: "#ffcc00",
			// 				overflowX: "scroll",
			// 				// width: 400,
			// 				maxWidth: 700,
			// 				height: 200
			// 				// backgroundColor: "#000"
			// 			}}
			// 		>
			// 			{text && !text.includes("Data type does not support yet!") ? (
			// 				<ReactJson
			// 					src={JSON.parse(text)}
			// 					indentWidth={0}
			// 					name={false}
			// 					displayDataTypes={false}
			// 				/>
			// 			) : (
			// 				<p>{text}</p>
			// 			)}
			// 		</div>
			// 	)
			// },
			// {
			// 	title: "xml payload",
			// 	dataIndex: "xml_payload",
			// 	key: "xml_payload",
			// 	width: "40%",
			// 	render: text => (
			// 		<div
			// 			style={{
			// 				// color: "#00ef00",
			// 				overflowX: "scroll",
			// 				// width: 400,
			// 				maxWidth: 600,
			// 				height: 200
			// 				// backgroundColor: "#000"
			// 			}}
			// 		>
			// 			<XMLViewer xml={text} />
			// 		</div>
			// 	)
			// },
			// {
			// 	title: () => (
			// 		<div>
			// 			xml payload{" "}
			// 			<Button
			// 				// type="primary"
			// 				size="small"
			// 				shape="round"
			// 				icon="code"
			// 				onClick={() =>
			// 					this.setState({ _prettify: !this.state._prettify })
			// 				}
			// 			>
			// 				{!this.state._prettify ? (
			// 					<span>prettify</span>
			// 				) : (
			// 					<span>string</span>
			// 				)}
			// 			</Button>
			// 		</div>
			// 	),
			// 	dataIndex: "xml_payload",
			// 	key: "xml_payload",
			// 	width: "50%",
			// 	render: text => (
			// 		<div
			// 			style={{
			// 				// color: "#00ef00",
			// 				overflowX: "scroll",
			// 				// width: 400,
			// 				maxWidth: 600,
			// 				height: 200
			// 				// backgroundColor: "#000"
			// 			}}
			// 		>
			// 			{this.state._prettify ? (
			// 				<XMLViewer xml={text} />
			// 			) : (
			// 				<span>{text}</span>
			// 			)}
			// 		</div>
			// 	)
			// }
		];

		function validatingJSON(json) {
			var checkedjson;
			try {
				checkedjson = JSON.parse(json);
			} catch (e) {}
			return checkedjson;
		}

		return (
			<Layout selectedKeys={['xmldata']} >
				<Modal
					visible={this.state.modalActive}
					title={this.state.dataType}
					// onOk={this.handleOk}
					onCancel={this.closeModal}
					style={{ top: 30 }}
					width="700px"
					bodyStyle={{
						maxHeight: "80vh",
						overflow: "scroll"
						// padding: 5
					}}
					footer={[
						<Button key="back" onClick={this.closeModal}>
							Return
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={() => {
								this.setState({ _prettify: !this.state._prettify });
							}}
							icon="code"
						>
							{this.state._prettify ? (
								<span>String</span>
							) : (
								<span>Prettify</span>
							)}
						</Button>
					]}
				>
					{this.state.dataType === "xml" &&
						(this.state._prettify ? (
							<XMLViewer xml={this.state.quickViewReport} />
						) : (
							<span>{this.state.quickViewReport}</span>
						))}
					{this.state.dataType === "readable" &&
						(this.state._prettify ? (
							validatingJSON(this.state.quickViewReport) ? (
								<ReactJson
									src={JSON.parse(this.state.quickViewReport)}
									indentWidth={0}
									name={false}
									displayDataTypes={false}
								/>
							) : (
								<span>Invalid object!</span>
							)
						) : (
							<span>{this.state.quickViewReport}</span>
						))}
					{this.state.dataType === "base64" &&
						(this.state._prettify ? (
							<span style={{ color: "#ef00ef" }}>
								{this.state.quickViewReport}
							</span>
						) : (
							<span>{this.state.quickViewReport}</span>
						))}
					{/* {!this.state.base64_payload ? (
						this.state.readableData ? (
							this.state._prettify ? (
								validatingJSON(this.state.quickViewReport) ? (
									<ReactJson
										src={JSON.parse(this.state.quickViewReport)}
										indentWidth={0}
										name={false}
										displayDataTypes={false}
									/>
								) : (
									<span>Invalid object!</span>
								)
							) : (
								<span>{this.state.quickViewReport}</span>
							)
						) : this.state._prettify ? (
							<XMLViewer xml={this.state.quickViewReport} />
						) : (
							<span>{this.state.quickViewReport}</span>
						)
					) : (
						<span>{this.state.quickViewReport}</span>
					)} */}
				</Modal>
				<Table
					columns={columns}
					dataSource={data}
					size="middle"
					// bordered
					loading={this.state.loading}
					title={() => <h2>XML Data</h2>}
					pagination={{ pageSize: 50 }}
					scroll={{ y: "75vh" }}
				/>
			</Layout>
		);
	}
}
