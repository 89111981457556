/*global google*/

import React from 'react';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker,
	DirectionsRenderer
} from 'react-google-maps';

export default class Map extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			directions: null
		};
	}

	componentDidMount() {
		const directionsService = new google.maps.DirectionsService();

		const origin = this.props.origin;
		const destination = this.props.destination;
		const waypoints = this.props.waypoints;
		// const destination = null;

		destination &&
			directionsService.route(
				{
					origin: origin,
					destination: destination,
					travelMode: google.maps.TravelMode.DRIVING,
					waypoints: waypoints
				},
				(result, status) => {
					if (status === google.maps.DirectionsStatus.OK) {
						this.setState({
							directions: result
						});
					} else {
						// console.error(`error fetching directions ${result}`);
						console.log('error');
					}
				}
			);
	}

	// shouldComponentUpdate(nextProps, nextState) {
	// 	if (
	// 		JSON.stringify(this.props.origin) === JSON.stringify(nextProps.origin)
	// 		// this.props.destination === this.nextProps.destination
	// 	) {
	// 		console.log("props", this.props);
	// 		return false;
	// 	} else {
	// 		console.log("curent props", this.props);
	// 		console.log("next props", nextProps);
	// 		return true;
	// 	}
	// }

	render() {
		const GoogleMapWindow = withGoogleMap(props => (
			<GoogleMap
				defaultCenter={this.props.origin}
				defaultZoom={13}
				options={{ streetViewControl: false }}
			>
				{this.state.directions ? (
					<DirectionsRenderer directions={this.state.directions} />
				) : (
					<Marker
						position={this.props.origin}
						label={{ text: 'S', color: 'white' }}
					/>
				)}
			</GoogleMap>
		));

		return (
			<div>
				<GoogleMapWindow
					containerElement={<div style={{ height: `600px`, width: '100%' }} />}
					mapElement={<div style={{ height: `100%` }} />}
				/>
			</div>
		);
	}
}