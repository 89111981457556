import React, { useState, useEffect } from 'react';
import axios from 'axios';
import URL from '../config/config';
import {
	Table,
	Input,
	Modal,
	Button,
	Divider,
	Icon,
	Tag,
	Card,
	DatePicker,
	Form,
	Checkbox,
	Row,
	Col,
	// Divider, Tag, Pagination, Input, Button, Icon
} from 'antd';
import 'antd/dist/antd.css';
import ReactJson from 'react-json-view';
import timeConvert from '../helper/data_convert';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import Layout from './layout';

export default function DataPackage() {
	let { search } = useLocation();
	const [rawData, setRawData] = useState();
	const [err, setErr] = useState();
	const [easterEgg, setEasterEgg] = useState(false)

	useEffect(() => {
		!rawData &&
			axios
				.get(`${URL.server}/finddatabyid${search}`)
				.then((res) => {
					setRawData(res.data);
					console.log(res.data);
				})
				.catch((error) => {
					setErr(error);
					console.log(error);
				});
	}, [rawData, search]);

	return (
		<Layout>
			{err && <>{JSON.stringify(err)}</>}
			{!rawData ? (
				<LoadingOutlined />
			) : (
				<>
					<Card title="Detail" style={{ width: '100%' }}>
						<Row gutter={[16, 16]}>
							<Col
								md={6}
								sm={24}
								xs={24}
								// style={{ height: '80vh', overflowY: 'scroll' }}
							>
								<Card type="inner">
									<p>id: {rawData._id}</p>
									<p>vin: {rawData.vin}</p>
									<p>deviceID: {rawData.deviceID}</p>
                  <p>CreatedAt: {timeConvert(rawData.createdAt)}</p>
								</Card>
                <p>Payload from tool:</p>
								<ReactJson
									src={{ payload: rawData.payLoad }}
									indentWidth={0}
									name={false}
									displayDataTypes={false}
									style={{ overflow: 'scroll' }}
								/>
								{easterEgg && <>
								<Button onClick={()=>{
									let a = Buffer.from(rawData.payLoad, 'hex').toJSON();
									console.log(a)
									const url = `https://locals.innovavietnam.com/v2/msend?vin=${rawData.vin}`
									// const url = `http://localhost:4004/v2/msend?vin=${rawData.vin}`
									
									axios.post(url, a, {
										headers: {
											'x-innova-device': '_' + rawData.deviceID,
											'Content-Type': 'application/json',
										}
									}).then(res => {
										setEasterEgg(false)
										console.log(res)
									}).catch(err=>{
										setEasterEgg(false)
										console.log(err)
									})
								}}>re-submit</Button>
								<Button onClick={()=>{
									let a = Buffer.from(rawData.payLoad, 'hex').toJSON();
									console.log(a)
									const url = `https://locals.innovavietnam.com/v2/msendstag?vin=${rawData.vin}`
									
									axios.post(url, a, {
										headers: {
											'x-innova-device': '_' + rawData.deviceID,
											'Content-Type': 'application/json',
										}
									}).then(res => {
										setEasterEgg(false)
										console.log(res)
									}).catch(err=>{
										setEasterEgg(false)
										console.log(err)
									})
								}}>re-submit stag</Button>
								</>}
								<div style={{position: 'absolute', bottom: '0'}} onClick={()=>{
									setEasterEgg(!easterEgg)
								}}>
									{' '} <span style={{color: '#fff'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
								</div>
							</Col>
							<Col
								md={8}
								sm={24}
								xs={24}
								style={{ height: '80vh', overflowY: 'scroll' }}
							>
								<p>readableData:</p>
								<ReactJson
									src={JSON.parse(rawData.readableData)}
									indentWidth={0}
									name={false}
									displayDataTypes={false}
								/>
								{/* {rawData.readableData.includes('latitude') && <>
								<iframe
									width="100%"
									height="400"
									frameborder="0"
									scrolling="no"
									marginheight="0"
									marginwidth="0"
									src={`https://maps.google.com/maps?q=${JSON.parse(rawData.readableData).filter(a => a.latitude)[0].latitude},${JSON.parse(rawData.readableData).filter(a => a.latitude)[0].longitude}&hl=&z=12&amp&iwloc=&output=embed&ie=UTF8`}
								/>
								</>} */}
							</Col>
							<Col
								md={10}
								sm={24}
								xs={24}
								style={{ height: '80vh', overflowY: 'scroll' }}
							>
								<p>
									rawData: &nbsp;
									{JSON.parse(rawData.rawData)['api_server_ip'] === '34.210.160.172' ? (
										<Tag color={'green'}>Stag</Tag>
									) : (
										<Tag color={'volcano'}>Dev</Tag>
									)}
									{JSON.parse(rawData.rawData)['data_type'] ? (
										<Tag color={'blue'}>{JSON.parse(rawData.rawData)['data_type']}</Tag>
									) : (
										JSON.parse(rawData.rawData).rawExtracted.map((a) => (
											<Tag color={'cyan'}>{a.data_type}</Tag>
										))
									)}
								</p>
								<ReactJson
									src={JSON.parse(rawData.rawData)}
									indentWidth={0}
									name={false}
									displayDataTypes={false}
								/>
							</Col>
							<Col md={12} sm={24} xs={24}></Col>
						</Row>
					</Card>
					{/* <div style={{ width: '100%', overflow: 'hidden' }}>
						{JSON.stringify(rawData)}
					</div> */}
				</>
			)}
		</Layout>
	);
}
