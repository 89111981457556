import React from 'react';
import XMLReport from './components/xmlReport';
import XMLData from './components/xmlData';
import { Link } from 'react-router-dom';
import './App.css';
import Layout from './components/layout';

class App extends React.Component {
	render() {
		return (
			<Layout selectedKeys={['home']}>
				<div className={`container`}>
					<main className={`main`}>
						{/* <h1 className={`title`}>
							Welcome to <a href="/">LocalServer!</a>
						</h1> */}

						{/* <p className={description}>
							Get started by editing{' '}
							<code className={code}>pages/index.js</code>
						</p> */}

						<div className={`grid`}>
							<Link to="/doc" className={`card`}>
								<h3>Documentation &rarr;</h3>
								<p>information about API.</p>
							</Link>

							<Link to="/rawdata" className={`card`}>
								<h3>RawData &rarr;</h3>
								<p>latest rawdata has been sent to server</p>
							</Link>

							<Link to="/searchrawdata" className={`card`}>
								<h3>Find rawData &rarr;</h3>
								<p>find rawData store in local server</p>
							</Link>

							<Link to="/xmldata" className={`card`}>
								<h3>XMLData &rarr;</h3>
								<p>latest xmlData has been sent to server</p>
							</Link>

							<Link to="/getprofile" className={`card`}>
								<h3>Tool get profile &rarr;</h3>
								<p>tool get profile & decode vin</p>
							</Link>

							<Link to="/gpsdemo" className={`card`}>
								<h3>Gps Demo &rarr;</h3>
								<p>demo gps data</p>
							</Link>
						</div>
					</main>
				</div>
			</Layout>
		);
	}
}

export default App;
